import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, FormGroup, Label } from 'reactstrap';
import AsyncSelect from 'react-select/async';
import CreatableSelect from 'react-select/creatable';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import instance from '@helpers/request/instance';
import Flatpickr from 'react-flatpickr';
import moment from 'moment';
import { Portuguese } from 'flatpickr/dist/l10n/pt';
import { isNumber } from 'lodash';
import classNames from 'classnames';
import { PERCENTAGE_MANDATORY } from '@modules/ProfitabilityGroup/constants';
import {
  EXPIRATION_DATE_MANDATORY,
  PARTNER_MANDATORY,
} from '@modules/Contract/submodules/Partner/constants';

interface Props {
  isOpen: boolean;
  toggle: () => void;
  onConfirm: (
    withPartner: boolean,
    partnerId?: string,
    expirationDate?: string,
    partnerPercentage?: number,
  ) => void;
  isLoading?: boolean;
}

export const LinkPartnerModal: React.FC<Props> = ({ isOpen, toggle, onConfirm, isLoading }) => {
  const { layoutModeTypes } = useSelector((state: any) => state.Layout);

  const validation = useFormik({
    initialValues: {
      partner: {
        value: '',
        label: '',
      },
      expirationDate: moment().add(5, 'months').format('YYYY-MM-DD'),
      percentage: 0,
    },
    validationSchema: Yup.object({
      partner: Yup.object({
        value: Yup.string().required(PARTNER_MANDATORY),
      }),
      expirationDate: Yup.string().required(EXPIRATION_DATE_MANDATORY),
      percentage: Yup.number().required(PERCENTAGE_MANDATORY),
    }),
    onSubmit: (values: {
      partner: { value: string; label: string };
      expirationDate: string;
      percentage: number;
    }) => {
      onConfirm(true, values.partner?.value, values.expirationDate, values.percentage);
    },
  });

  const getPartners = (name: string, callback: (data: any) => void) => {
    new Promise((resolve, reject) => {
      instance
        .get(`/partners?name_like=${name}`)
        .then((response) => {
          const data = response.data.items.map((partner: any) => ({
            value: partner.id,
            label: partner.name,
          }));

          callback(data);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  };

  const handlePercentage = (data: any) => {
    let value = 0;

    if (data?.value) {
      value = isNumber(data.value) ? data.value : data.value.replace(',', '.');
    }

    validation.setFieldValue('percentage', Number(value));
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered>
      <form onSubmit={validation.handleSubmit}>
        <ModalHeader toggle={toggle}>Vincular acessor</ModalHeader>
        <ModalBody>
          <p>Deseja vincular um acessor a esta transação?</p>
          <FormGroup>
            <Label>Selecione um acessor</Label>
            <AsyncSelect
              isClearable
              cacheOptions
              defaultOptions
              loadOptions={getPartners}
              value={validation.values.partner}
              onChange={(newValue) => validation.setFieldValue('partner', newValue)}
              placeholder="Buscar acessor..."
              className={classNames('react-select-container react-select-container--z-index-max', {
                'is-invalid': validation.touched.partner && validation.errors.partner,
              })}
              classNames={{
                singleValue: () => 'react-select-single-value',
                placeholder: () => 'react-select-placeholder',
              }}
              theme={(theme) => ({
                ...theme,
                colors:
                  layoutModeTypes === 'dark'
                    ? {
                        ...theme.colors,
                        primary25: '#272727',
                        primary: '#272727',
                        neutral0: '#1E1E1E',
                      }
                    : theme.colors,
              })}
            />
            {validation.touched.partner?.value && validation.errors.partner?.value && (
              <div className="invalid-feedback d-block">{validation.errors.partner.value}</div>
            )}
          </FormGroup>
          <FormGroup>
            <Label>Data de expiração</Label>
            <Flatpickr
              className={classNames('form-control d-block', {
                'is-invalid': validation.touched.expirationDate && validation.errors.expirationDate,
              })}
              placeholder="Selecione uma data"
              value={validation.values.expirationDate || ''}
              onChange={([date]) =>
                validation.setFieldValue('expirationDate', moment(date).format('YYYY-MM-DD'))
              }
              options={{
                dateFormat: 'Y-m-d',
                altInput: true,
                altFormat: 'd/m/Y',
                locale: Portuguese,
                minDate: 'today',
              }}
            />
            {validation.touched.expirationDate && validation.errors.expirationDate && (
              <div className="invalid-feedback">{validation.errors.expirationDate as any}</div>
            )}
          </FormGroup>
          <FormGroup>
            <Label>Porcentagem do acessor</Label>
            <CreatableSelect
              isClearable
              value={{
                label: validation.values.percentage,
                value: validation.values.percentage,
              }}
              onChange={handlePercentage}
              options={[
                { label: 0.5, value: 0.5 },
                { label: 0.3, value: 0.3 },
                { label: 0.2, value: 0.2 },
              ]}
              placeholder="Digite ou selecione a porcentagem"
              formatCreateLabel={(inputValue) => `Adicionar porcentagem: ${inputValue}%`}
              className={classNames('react-select-container', {
                'is-invalid': validation.touched.percentage && validation.errors.percentage,
              })}
              classNames={{
                singleValue: () => 'react-select-single-value',
                placeholder: () => 'react-select-placeholder',
              }}
              theme={(theme) => ({
                ...theme,
                colors:
                  layoutModeTypes === 'dark'
                    ? {
                        ...theme.colors,
                        primary25: '#272727',
                        primary: '#272727',
                        neutral0: '#1E1E1E',
                      }
                    : theme.colors,
              })}
            />
            {validation.touched.percentage && validation.errors.percentage && (
              <div className="invalid-feedback d-block">{validation.errors.percentage}</div>
            )}
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button type="submit" color="success" disabled={isLoading || !validation.isValid}>
            Sim, vincular acessor
          </Button>
          <Button
            type="button"
            color="danger"
            onClick={() => onConfirm(false)}
            disabled={isLoading}
          >
            Não vincular
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};
