import React, { useCallback, useState } from 'react';
import { Button, Modal, ModalBody } from 'reactstrap';

import * as S from './styles';

import { IManageTransactionsModalProps } from './types';
export const ManageTransactionsModal = ({ showModal, toggle }: IManageTransactionsModalProps) => {
  // * [States] * //
  const [transactionType, setTransactionType] = useState('expense');
  const [accordionOpen, setAccordionOpen] = useState<string[]>([]);

  // * [Functions] * //
  const toggleModal = useCallback(() => {
    toggle();
  }, []);

  const handleAccordionOpen = useCallback((id: string) => {
    setAccordionOpen((prevState) => {
      if (prevState.includes(id)) {
        return prevState.filter((item) => item !== id);
      }

      return [...prevState, id];
    });
  }, []);

  // * [Objects] * //
  const OPTIONS = [
    { value: 'income', label: 'Receita' },
    { value: 'expense', label: 'Despesa' },
    { value: 'transfer', label: 'Transferência' },
  ];

  return (
    <Modal isOpen={showModal} toggle={toggleModal} centered>
      <S.ManageTransactionsModalHeader toggle={toggleModal}>
        <S.TransactionTypeInput
          type="select"
          name="transactionType"
          value={transactionType}
          onChange={(e) => setTransactionType(e.target.value)}
        >
          {OPTIONS.map((option) => (
            <S.FieldOption key={option.value} value={option.value}>
              {option.label}
            </S.FieldOption>
          ))}
        </S.TransactionTypeInput>
      </S.ManageTransactionsModalHeader>

      <ModalBody>
        <S.FormFieldGroup>
          <S.FieldLabel for="description">Descrição</S.FieldLabel>
          <S.InputField type="text" name="description" id="description" placeholder="" />
        </S.FormFieldGroup>

        <S.FieldWrapper>
          <S.FormFieldGroup>
            <S.FieldLabel for="value">Valor</S.FieldLabel>
            <S.InputField type="text" name="value" id="value" placeholder="R$ 0,00" />
          </S.FormFieldGroup>

          <S.FormFieldGroup>
            <S.FieldLabel for="date">Data</S.FieldLabel>
            <S.InputField type="date" name="date" id="date" placeholder="" />
          </S.FormFieldGroup>
        </S.FieldWrapper>

        <S.FieldWrapper>
          <S.FormFieldGroup>
            <S.FieldLabel for="account">Conta/Cartão</S.FieldLabel>
            <S.InputField type="select" name="account" id="account" placeholder="">
              <S.FieldOption value="1">Conta 1</S.FieldOption>
              <S.FieldOption value="2">Conta 2</S.FieldOption>
            </S.InputField>
          </S.FormFieldGroup>

          <S.FormFieldGroup>
            <S.FieldLabel for="category">Categoria</S.FieldLabel>
            <S.InputField type="select" name="category" id="category" placeholder="">
              <S.FieldOption value="1">Categoria 1</S.FieldOption>
              <S.FieldOption value="2">Categoria 2</S.FieldOption>
            </S.InputField>
          </S.FormFieldGroup>
        </S.FieldWrapper>

        <S.AccordionWrapper>
          <S.TransactionAccordion flush open={accordionOpen} toggle={handleAccordionOpen}>
            <S.TransactionAccordionItem>
              <S.TransactionAccordionHeader targetId="repeat">
                <S.Icon className="mdi mdi-repeat" />
                Repetir
              </S.TransactionAccordionHeader>
              <S.TransactionAccordionBody accordionId="repeat">
                <S.FormFieldGroup switch>
                  <S.InputField
                    type="switch"
                    role="switch"
                    name="fixed"
                    id="fixed"
                    placeholder=""
                  />
                  <S.FieldLabel check for="fixed">
                    é uma despesa fixa
                  </S.FieldLabel>
                </S.FormFieldGroup>

                <S.FormFieldGroup switch marginTop={8}>
                  <S.InputField
                    type="switch"
                    role="switch"
                    name="recurring"
                    id="recurring"
                    placeholder=""
                  />
                  <S.FieldLabel check for="recurring">
                    é uma despesa parcelada
                  </S.FieldLabel>
                </S.FormFieldGroup>
              </S.TransactionAccordionBody>
            </S.TransactionAccordionItem>
          </S.TransactionAccordion>

          <S.TransactionAccordion flush open={accordionOpen} toggle={handleAccordionOpen}>
            <S.TransactionAccordionItem>
              <S.TransactionAccordionHeader targetId="observation">
                <S.Icon className="mdi mdi-message-text" />
                Observação
              </S.TransactionAccordionHeader>
              <S.TransactionAccordionBody accordionId="observation">
                <S.FormFieldGroup>
                  <S.InputField
                    type="textarea"
                    name="observation"
                    id="observation"
                    placeholder=""
                  />
                </S.FormFieldGroup>
              </S.TransactionAccordionBody>
            </S.TransactionAccordionItem>
          </S.TransactionAccordion>

          <S.TransactionAccordion flush open={accordionOpen} toggle={handleAccordionOpen}>
            <S.TransactionAccordionItem>
              <S.TransactionAccordionHeader targetId="tags">
                <S.Icon className="mdi mdi-tag-multiple" />
                Tags
              </S.TransactionAccordionHeader>
              <S.TransactionAccordionBody accordionId="tags">
                <S.FormFieldGroup>
                  <S.InputField type="select" name="tags" id="tags" placeholder="">
                    <S.FieldOption value="0">Selecionar</S.FieldOption>
                    <S.FieldOption value="1">Tag 1</S.FieldOption>
                    <S.FieldOption value="2">Tag 2</S.FieldOption>
                  </S.InputField>
                </S.FormFieldGroup>
              </S.TransactionAccordionBody>
            </S.TransactionAccordionItem>
          </S.TransactionAccordion>
        </S.AccordionWrapper>
      </ModalBody>

      <S.ManageTransactionsModalFooter>
        <Button color="primary" onClick={() => {}} disabled={false}>
          Salvar
        </Button>
      </S.ManageTransactionsModalFooter>
    </Modal>
  );
};
