import { InstanceError } from '@apptypes/instance';
import { ERROR_STATUS_MESSAGE } from '@helpers/request/constants';
import instance from '@helpers/request/instance';
import moment from 'moment';

export const linkPartner = async (
  contractID: string,
  partnerID: string,
  expirationDate: string,
  partnerPercentage: number,
): Promise<void> => {
  try {
    await instance.put(`/contracts/${contractID}`, {
      partner_id: partnerID,
      linked_partner_at: moment().format('YYYY-MM-DD'),
      expiration_partner_at: expirationDate,
      partner_percentage: partnerPercentage,
    });
  } catch (error) {
    const errorMessage: Record<number, string> = {
      [ERROR_STATUS_MESSAGE.BAD_REQUEST]: 'Não foi possível vincular o acessor ao contrato',
      [ERROR_STATUS_MESSAGE.NOT_FOUND]: 'contrato não encontrado',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
    };

    throw new Error(
      errorMessage[(error as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};

export const unlinkPartner = async (contractID: string, payments: string[]): Promise<void> => {
  try {
    await instance.put(`/contracts/${contractID}/unlink-partner`, {
      payments,
    });
  } catch (error) {
    const errorMessage: Record<number, string> = {
      [ERROR_STATUS_MESSAGE.BAD_REQUEST]: 'Não foi possível desvincular o acessor ao contrato',
      [ERROR_STATUS_MESSAGE.NOT_FOUND]: 'contrato não encontrado',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
    };

    throw new Error(
      errorMessage[(error as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};
