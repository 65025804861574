import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { useQuery } from '@tanstack/react-query';

import { IWithRouter } from '@apptypes/WithRouter';
import { Page } from '@component/common/Page';
import TableContainer from '@common/NewTableContainer';
import withRouter from '@common/withRouter';
import { buildColumns } from '@modules/DailyOperations/components/Table/constants';
import { getAll } from '@modules/DailyOperations/services';
import ActionModal from '../components/ActionModal';

const DailyOperations = ({ router, page, size, setSearchParams }: IWithRouter) => {
  const [filters, setFilters] = useState<string>('');
  const [modal, setModal] = useState<boolean>(false);

  const { data, isFetching, refetch } = useQuery({
    queryKey: ['getAllDailyOperations', page, size, filters],
    queryFn: () => getAll({ page, limit: size, filters }),
    initialData: {
      page: {
        limit: 10,
        page: 1,
        totalRows: 0,
        totalPages: 0,
      },
      items: [],
    },
    retry: 1,
  });

  useEffect(() => {
    if (!modal) {
      refetch();
    }
  }, [modal]);

  return (
    <Page
      title="Operações diárias"
      breadcrumb={{
        title: 'Operações diárias',
        titleHref: '/daily-operations',
        breadcrumbItem: 'Listar operações diárias',
      }}
    >
      <Row>
        <Col lg="12">
          <ActionModal router={router} isOpen={modal} toogleModal={() => setModal(!modal)} />
          <Card>
            <CardBody>
              <TableContainer
                moduleName="daily-operations"
                columns={buildColumns()}
                currentPage={page}
                customPageSize={size}
                data={data.items}
                loading={isFetching}
                totalItems={data.page.totalPages}
                handleFilters={setFilters}
                setSearchParams={setSearchParams}
                refetch={refetch}
                disableHover
                isPagination
                isShowingPageLength
              />
            </CardBody>
          </Card>
        </Col>

        <div className="action-button">
          <button
            type="button"
            className="action-button__button action-button__button--close"
            onClick={() => setModal(!modal)}
          >
            <i className="mdi mdi-close"></i>
          </button>
        </div>
      </Row>
    </Page>
  );
};

export default withRouter(DailyOperations);
