import React, { createContext, useContext, useState } from 'react';
import { toast } from 'react-toastify';

import useDataFetch from '@modules/Finance/hooks/useDataFetch';
import { getAllExpenses, getAllIncomes } from '@modules/Finance/services/transactions';

import { Repetition, TransactionTypeEnum } from '@modules/Finance/ultis/transactions/enums';

import { IExpenseData, IIncomeData } from '@modules/Finance/types/transactions';

import {
  Filters,
  StartEnd,
  TransactionsContextType,
  TransactionsProviderProps,
  Value,
} from './types';
import { useFormik } from 'formik';

export const TransactionsContext = createContext<TransactionsContextType | undefined>(undefined);

export default function TransactionsProvider({ children }: TransactionsProviderProps) {
  // * [Datas] * //
  const {
    data: expenses,
    setData: setExpenses,
    loading: loadingExpenses,
    setLoading: setLoadingExpenses,
  } = useDataFetch<IExpenseData | null>(null);

  const {
    data: incomes,
    setData: setIncomes,
    loading: loadingIncomes,
    setLoading: setLoadingIncomes,
  } = useDataFetch<IIncomeData | null>(null);

  // * [States] * //
  const [showManageTrasactionsModal, setShowManageTransactionsModal] = useState<boolean>(false);
  const [transactionActionType, setTransactionActionType] = useState<TransactionTypeEnum>(
    TransactionTypeEnum.Expense,
  );
  const [selectedDate, setSelectedDate] = useState<Value>(new Date());
  const [startEndDate, setStartEndDate] = useState<StartEnd>({
    start: new Date(),
    end: new Date(),
  });
  // * [Functions] * //
  const handleToggleManageTransactionsModal = () => {
    setShowManageTransactionsModal((prev) => !prev);
    setExpenses(null);
  };

  const handleGetAllExpenses = async () => {
    setLoadingExpenses(true);

    const repetitionValues = [
      filters.values['unique-checkbox'] && `${Repetition.Unique}`,
      filters.values['installments-checkbox'] && `${Repetition.Recurring}`,
      filters.values['fixed-checkbox'] && `${Repetition.Fixed}`,
    ].filter(Boolean);

    try {
      const response = await getAllExpenses(
        new Date(String(startEndDate.start)).toISOString(),
        new Date(String(startEndDate.end)).toISOString(),
        repetitionValues.length > 0 ? `repetition:in:${repetitionValues.join('|')}` : undefined,
      );

      setExpenses(response);
    } catch (error: any) {
      toast.error(error.message || 'Erro ao buscar despensas');
    } finally {
      setLoadingExpenses(false);
    }
  };

  const handleGetAllIncomes = async () => {
    setLoadingIncomes(true);

    try {
      const response = await getAllIncomes(
        new Date(String(startEndDate.start)).toISOString(),
        new Date(String(startEndDate.end)).toISOString(),
      );

      setIncomes(response);
    } catch (error: any) {
      toast.error(error.message || 'Erro ao buscar receitas');
    } finally {
      setLoadingIncomes(false);
    }
  };

  const submitFilters = (filters: Filters) => {
    if (filters['type-transaction'] === TransactionTypeEnum.Expense) {
      setTransactionActionType(TransactionTypeEnum.Expense);
      handleGetAllExpenses();
    }

    if (filters['type-transaction'] === TransactionTypeEnum.Income) {
      setTransactionActionType(TransactionTypeEnum.Income);
      handleGetAllIncomes();
    }
  };

  const filters = useFormik({
    enableReinitialize: false,
    initialValues: {
      'type-transaction': String(TransactionTypeEnum.Expense),
      'unique-checkbox': true,
      'installments-checkbox': true,
      'fixed-checkbox': true,
    },
    onSubmit: async (value) => submitFilters(value),
  });

  return (
    <TransactionsContext.Provider
      value={{
        expenses,
        incomes,
        transactionActionType,
        selectedDate,
        startEndDate,
        filters,
        //
        setTransactionActionType,
        setSelectedDate,
        setStartEndDate,
        //
        loadingExpenses,
        loadingIncomes,
        showManageTrasactionsModal,
        //
        handleGetAllExpenses,
        handleGetAllIncomes,
        handleToggleManageTransactionsModal,
      }}
    >
      {children}
    </TransactionsContext.Provider>
  );
}

export const useTransactions = () => {
  const context = useContext(TransactionsContext);

  if (!context) {
    throw new Error('useTransactions must be used within a TransactionsProvider');
  }

  return context;
};
