import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Form,
  FormGroup,
  InputGroup,
  Label,
  Row,
} from 'reactstrap';

import withRouter from '@common/withRouter';
import { Page } from '@component/common/Page';

import { Portuguese } from 'flatpickr/dist/l10n/pt';
import Flatpickr from 'react-flatpickr';
import moment from 'moment';
import { useMutation } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import AsyncSelect from 'react-select/async';
import ReactEcharts from 'echarts-for-react';

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import { getDailyEarnings } from '../services';
import { financialApiInstance } from '@helpers/request/instance';
import { IWallet } from '@modules/Wallets/types';

import { options } from '../components/DailyEarnings';
import { IFinancePagination } from '@apptypes/pagination';
import { DailyEarnings as IDailyEarnings } from '../types';
import { MultiValue } from 'react-select';

const DailyEarnings = () => {
  const { layoutModeTypes } = useSelector((state: any) => state.Layout);
  const [items, setItems] = useState<IDailyEarnings[]>([]);

  const { mutate, isPending } = useMutation({
    mutationFn: ({
      walletIds,
      initialDate,
      finalDate,
    }: {
      walletIds: string;
      initialDate: Date;
      finalDate: Date;
    }) => getDailyEarnings({ walletIds, initialDate, finalDate }),
    onSuccess: (data: IFinancePagination<IDailyEarnings>) => {
      setItems(data.items || []);
    },
  });

  useEffect(() => {
    mutate({
      walletIds: '',
      initialDate: moment().startOf('day').subtract(1, 'months').toDate(),
      finalDate: moment().endOf('day').toDate(),
    });
  }, []);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      wallets: Array<{
        value: '';
        label: '';
      }>,
      dates: [
        moment().startOf('day').subtract(1, 'months').toDate(),
        moment().startOf('day').toDate(),
      ],
    },
    onSubmit: async (payload: any) => {
      const wallets = Array.isArray(payload.wallets) ? payload.wallets : [];
      const walletIds: string = wallets.reduce((acc, cur) => acc + cur.value + ',', '') || '';

      mutate({
        walletIds: walletIds.substring(0, walletIds.length - 1),
        initialDate: payload.dates[0],
        finalDate: payload.dates[1],
      });
    },
  });

  const handleWallets = (data: MultiValue<{ value: string; label: string }>) => {
    validation.setFieldValue('wallets', data);
  };

  const getWallets = (inputValue: string, callback: (data: any) => void) => {
    new Promise((resolve, reject) => {
      const searchFilter = inputValue ? `&filters=name:like:${inputValue}` : '';

      financialApiInstance
        .get(`/api/v1/wallets?page=1&limit=100${searchFilter}`)
        .then((response) => {
          const data = response.data.wallets.map((wallet: IWallet) => ({
            value: wallet.id,
            label: wallet.name,
          }));

          callback(data);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  };

  return (
    <Page
      title="Relatórios"
      breadcrumb={{
        title: 'Relatórios',
        titleHref: '/reports',
        breadcrumbItem: 'Relatório de ganhos diários',
      }}
    >
      <Row>
        <Col lg="12">
          <Card>
            <CardBody>
              <CardTitle>Relatório de ganhos diários</CardTitle>

              <Form
                className=""
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                }}
              >
                <Row>
                  <FormGroup className="col-md-2 pe-0">
                    <Label className="col-form-label me-2">Selecione um período</Label>
                    <Col lg={12}>
                      <InputGroup style={{ width: '200px', maxWidth: '200px' }}>
                        <Flatpickr
                          name="dates"
                          className="form-control d-block"
                          placeholder="Selecione uma data"
                          onChange={(dates) => {
                            validation.setFieldValue('dates', dates);
                          }}
                          options={{
                            mode: 'range',
                            altInput: true,
                            altFormat: 'd/m/Y',
                            dateFormat: 'Y-m-d',
                            defaultDate: [
                              moment().startOf('day').subtract(1, 'months').toDate(),
                              moment().startOf('day').toDate(),
                            ],
                            disable: [
                              function (date: Date) {
                                const today = moment().startOf('day');
                                const oneYearAgo = moment().subtract(1, 'years').startOf('day');

                                const momentDate = moment(date);
                                return momentDate.isBefore(oneYearAgo) || momentDate.isAfter(today);
                              },
                            ],
                            locale: Portuguese,
                          }}
                        />
                      </InputGroup>
                    </Col>
                  </FormGroup>
                  <FormGroup className="col-md-4">
                    <Label className="col-form-label col-lg-12">Carteiras</Label>
                    <Col lg={12}>
                      <AsyncSelect
                        name="wallets"
                        isMulti
                        className="react-select-container"
                        classNames={{
                          multiValue: () => 'react-select-multi-value',
                          placeholder: () => 'react-select-placeholder',
                        }}
                        aria-placeholder="Selecione uma carteira..."
                        cacheOptions
                        defaultOptions
                        loadOptions={getWallets}
                        onChange={handleWallets}
                        value={validation.values.wallets}
                        theme={(theme) => {
                          if (layoutModeTypes !== 'dark') {
                            return theme;
                          }

                          return {
                            ...theme,
                            colors: {
                              ...theme.colors,
                              primary25: '#272727',
                              primary: '#272727',
                              neutral0: '#1E1E1E',
                            },
                          };
                        }}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup className="col-md-2 d-flex justify-content-start align-items-end ps-0">
                    <Col lg={12} className="d-flex justify-content-start align-items-end">
                      <Button color="primary" type="submit" disabled={isPending}>
                        Pesquisar
                      </Button>
                    </Col>
                  </FormGroup>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col lg="12">
          {items.length ? (
            <ReactEcharts style={{ height: '500px' }} option={options({ items })} />
          ) : (
            <Skeleton height={500} />
          )}
        </Col>
      </Row>
    </Page>
  );
};

export default withRouter(DailyEarnings);
