import React from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { Badge, Input } from 'reactstrap';
import { moneyTransform } from '@helpers/transforms/money';

export const buildColumns = (selectedLines: any, handleSelectedLines: (data: any) => void) => {
  const columnHelper = createColumnHelper();

  return [
    columnHelper.accessor('contract.id', {
      cell: ({ row, getValue }: any) => (
        <>
          <Input
            type="checkbox"
            className="me-4"
            checked={selectedLines.some(
              (line: any) => line.contract.id === row.original.contract.id,
            )}
            onChange={(e) => {
              e.preventDefault();
              handleSelectedLines(row.original);
            }}
          />
          <Badge color="primary" className="font-size-12" pill>
            {getValue()}
          </Badge>
        </>
      ),
      header: 'ID do contrato',
      meta: {
        disableFilterType: true,
      },
    }),
    columnHelper.accessor('contract.type', {
      cell: ({ row, getValue }: any) => (
        <Badge color="primary" className="font-size-12" pill>
          {getValue()}
        </Badge>
      ),
      header: 'Tipo de contrato',
      meta: {
        filterType: 'select',
        filterOptions: [
          { value: '', label: 'Selecione...' },
          { value: 'true', label: 'Juros Simples' },
          { value: 'false', label: 'Juros Compostos' },
        ],
      },
    }),
    columnHelper.accessor('contract.profitability', {
      cell: ({ row, getValue }: any) => <h5 className="font-size-14 mb-1">{getValue()}%</h5>,
      header: 'Porcentagem do contrato',
      meta: {
        disableFilterType: true,
      },
    }),
    columnHelper.accessor('client.name', {
      cell: ({ row, getValue }: any) => <h5 className="font-size-14 mb-1">{getValue()}</h5>,
      header: 'Nome do cliente',
    }),
    columnHelper.accessor('client.code', {
      cell: ({ row, getValue }: any) => (
        <Badge color="primary" className="font-size-12" pill>
          {getValue()}
        </Badge>
      ),
      header: 'ID do cliente',
      meta: {
        disableFilterType: true,
      },
    }),
    columnHelper.accessor('current_custody', {
      cell: ({ row, getValue }: any) => (
        <h5 className="font-size-14 mb-1">{moneyTransform(getValue())}</h5>
      ),
      header: 'Custódia atual',
      meta: {
        disableFilter: true,
        disableOrderBy: true,
      },
    }),
    columnHelper.accessor('custody_filtered', {
      cell: ({ row, getValue }: any) => (
        <h5 className="font-size-14 mb-1">
          {Number(getValue()) ? (
            moneyTransform(Number(getValue()))
          ) : (
            <div className="d-flex justify-content-center text-muted">-</div>
          )}
        </h5>
      ),
      header: 'Custódia do dia',
      meta: {
        filterType: 'date',
        disableFilterType: true,
        disableOrderBy: true,
      },
    }),
    columnHelper.accessor('custody_monthly', {
      cell: ({ row, getValue }: any) => (
        <h5 className="font-size-14 mb-1">
          {Number(getValue()) ? (
            moneyTransform(Number(getValue()))
          ) : (
            <div className="d-flex justify-content-center text-muted">-</div>
          )}
        </h5>
      ),
      header: 'Custódia mensal',
      meta: {
        disableFilter: true,
        disableOrderBy: true,
      },
    }),
    columnHelper.accessor('daily_profitability', {
      cell: ({ row, getValue }: any) => (
        <h5 className="font-size-14 mb-1">
          {Number(getValue()) ? (
            moneyTransform(Number(getValue()))
          ) : (
            <div className="d-flex justify-content-center text-muted">-</div>
          )}
        </h5>
      ),
      header: 'Rentabilidade diária',
      meta: {
        disableFilter: true,
        disableOrderBy: true,
      },
    }),
    columnHelper.accessor('projection.monthly', {
      cell: ({ row, getValue }: any) => (
        <h5 className="font-size-14 mb-1">{moneyTransform(getValue())}</h5>
      ),
      header: 'Projeção mensal',
      meta: {
        disableFilter: true,
        disableOrderBy: true,
      },
    }),
    columnHelper.accessor('projection.bimonthly', {
      cell: ({ row, getValue }: any) => (
        <h5 className="font-size-14 mb-1">{moneyTransform(getValue())}</h5>
      ),
      header: 'Projeção bimestral',
      meta: {
        disableFilter: true,
        disableOrderBy: true,
      },
    }),
    columnHelper.accessor('projection.quarterly', {
      cell: ({ row, getValue }: any) => (
        <h5 className="font-size-14 mb-1">{moneyTransform(getValue())}</h5>
      ),
      header: 'Projeção trimestral',
      meta: {
        disableFilter: true,
        disableOrderBy: true,
      },
    }),
    columnHelper.accessor('projection.biannual', {
      cell: ({ row, getValue }: any) => (
        <h5 className="font-size-14 mb-1">{moneyTransform(getValue())}</h5>
      ),
      header: 'Projeção semestral',
      meta: {
        disableFilter: true,
        disableOrderBy: true,
      },
    }),
    columnHelper.accessor('projection.annual', {
      cell: ({ row, getValue }: any) => (
        <h5 className="font-size-14 mb-1">{moneyTransform(getValue())}</h5>
      ),
      header: 'Projeção anual',
      meta: {
        disableFilter: true,
        disableOrderBy: true,
      },
    }),
  ];
};
