import React, { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import './App.css';
import '@assets/scss/theme.scss';

import { getTheme } from 'theme';

import { authProtectedRoutes, publicRoutes } from '@routes/allRoutes';
import AuthProtected from '@routes/AuthProtected';

import ApplicationProviders from '@modules/Finance/contexts';

import VerticalLayout from './components/Layouts/VerticalLayout';
import NonAuthLayout from './components/Layouts/NonLayout';
import { useSelector } from 'react-redux';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const App = () => {
  const [theme, setTheme] = useState(getTheme());
  const layoutMode = useSelector((state: any) => state.Layout.layoutMode);

  useEffect(() => {
    setTheme(getTheme());
  }, [layoutMode]);

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <ApplicationProviders>
          <Routes>
            {publicRoutes.map((route, idx) => (
              <Route
                path={route.path}
                key={idx}
                element={<NonAuthLayout>{route.component}</NonAuthLayout>}
              />
            ))}
            {authProtectedRoutes.map((route, idx) => (
              <Route
                path={route.path}
                key={idx}
                element={
                  <AuthProtected>
                    <VerticalLayout>{route.component}</VerticalLayout>
                  </AuthProtected>
                }
              />
            ))}
          </Routes>
        </ApplicationProviders>
      </QueryClientProvider>
    </ThemeProvider>
  );
};

export default App;
