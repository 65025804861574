import React from 'react';
import { useMutation } from '@tanstack/react-query';
import { Row, Col, CardBody, Card, CardTitle } from 'reactstrap';
import * as Yup from 'yup';
import { useFormik } from 'formik';

import { IWithRouter } from '@apptypes/WithRouter';
import withRouter from '@common/withRouter';
import { Page } from '@component/common/Page';
import { REDIRECT_TIMEOUT } from '@helpers/constants';
import { create } from '@modules/Wallets/services';
import { WalletForm } from '@modules/Wallets/components';
import { IWalletForm } from '../types';
import {
  TYPE_ACCOUNT_TYPE,
  TYPE_BALANCE,
  TYPE_CLOSE_DAY,
  TYPE_DUE_DAY,
  TYPE_FIRST_DUE_DATE,
  TYPE_HOLDER_NAME,
  TYPE_ISSUER,
  TYPE_NAME,
  TYPE_NUMBER,
} from '../constants';

const WalletCreate = ({ router }: IWithRouter) => {
  const { mutate, error, isPending, isSuccess } = useMutation({
    mutationFn: (payload: IWalletForm) => create(payload),
    onSuccess: () => {
      setTimeout(() => {
        router.navigate('/wallets');
      }, REDIRECT_TIMEOUT);
    },
  });

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      active: 'true',
      balance: 0,
      closeDay: 0,
      dueDay: 0,
      firstDueDate: '',
      holderName: '',
      issuer: '',
      number: 0,
      name: '',
      type: '',
    },
    validationSchema: Yup.object({
      balance: Yup.number().required(TYPE_BALANCE),
      closeDay: Yup.number().required(TYPE_CLOSE_DAY),
      dueDay: Yup.number().required(TYPE_DUE_DAY),
      firstDueDate: Yup.string().required(TYPE_FIRST_DUE_DATE),
      holderName: Yup.string().required(TYPE_HOLDER_NAME),
      issuer: Yup.string().required(TYPE_ISSUER),
      number: Yup.number().required(TYPE_NUMBER),
      name: Yup.string().required(TYPE_NAME),
      type: Yup.string().required(TYPE_ACCOUNT_TYPE),
    }),
    onSubmit: (payload: IWalletForm) => mutate(payload),
  });

  return (
    <Page
      title="Criar carteira"
      breadcrumb={{
        title: 'Listar carteiras',
        titleHref: '/wallets',
        breadcrumbItem: 'Criar carteira',
      }}
    >
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <CardTitle className="mb-4">Criar nova carteira</CardTitle>
              <div className="p-2 mt-3">
                <WalletForm
                  validation={validation}
                  loading={isPending}
                  registrationError={error?.message}
                  showAlert={isSuccess}
                />
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Page>
  );
};

export default withRouter(WalletCreate);
