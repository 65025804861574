import React from 'react';
import classnames from 'classnames';
import { Nav, NavItem, NavLink } from 'reactstrap';

import { ITabsProps } from './types';

export const Tabs = <T,>({ activeTab, toggleTab, tabs }: ITabsProps<T>) => (
  <Nav tabs className="nav-tabs-custom justify-content-center gap-5 pt-2" role="tablist">
    {tabs.map((tab) => (
      <NavItem key={String(tab.value)}>
        <NavLink
          to="#"
          className={classnames({
            active: activeTab === tab.value,
          })}
          onClick={() => toggleTab(tab.value)}
        >
          {tab.label}
        </NavLink>
      </NavItem>
    ))}
  </Nav>
);
