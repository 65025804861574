import { InstanceError } from '@apptypes/instance';
import { AUTH_COOKIE_NAME } from '@helpers/constants';
import { getCookie } from '@helpers/cookies';
import { ERROR_STATUS_MESSAGE } from '@helpers/request/constants';
import financialMarketInstance from '@helpers/request/financialMarket';
import instance from '@helpers/request/instance';
import { ColumnFiltersState } from '@tanstack/react-table';
import moment from 'moment';

import { IFinancePagination } from '@apptypes/pagination';
import { DailyEarnings } from '../types';

export const getCustodyReport = async (projectionMonths: number) => {
  try {
    const { data } = await instance.get(
      `reports/custody?projection_months_count=${projectionMonths}`,
    );
    return data;
  } catch (error) {
    const errorMessage: Record<string, string> = {
      [ERROR_STATUS_MESSAGE.NOT_FOUND]: 'transações não encontradas',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
    };

    throw new Error(
      errorMessage[(error as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};

export const getCustodyDetaleidReport = async ({
  filters,
  page,
  size,
}: {
  filters?: ColumnFiltersState;
  page: number;
  size: number;
}) => {
  try {
    const queryString = customGenerateToQueryString({
      filters,
      page,
      size,
    });

    const { data } = await instance.get(`reports/custody-details${queryString}`);
    return data;
  } catch (error) {
    const errorMessage: Record<string, string> = {
      [ERROR_STATUS_MESSAGE.NOT_FOUND]: 'transações não encontradas',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
    };

    throw new Error(
      errorMessage[(error as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};

export const exportData = async () => {
  try {
    const file = await fetch(`${process.env.BASE_URL}/reports/custody-details/export`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: getCookie(AUTH_COOKIE_NAME),
      } as any,
    });

    const response = await file.arrayBuffer();
    const fileBlog = new Blob([response], { type: 'text/csv' });

    return URL.createObjectURL(fileBlog);
  } catch (error) {
    const errorMessage: Record<number, string> = {
      [ERROR_STATUS_MESSAGE.BAD_REQUEST]: 'Não foi possível buscar o arquivo',
      [ERROR_STATUS_MESSAGE.NOT_FOUND]: 'arquivo não encontrado',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
    };

    throw new Error(
      errorMessage[(error as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};

export const getDailyEarnings = async ({
  walletIds,
  initialDate,
  finalDate,
}: {
  walletIds: string;
  initialDate: Date;
  finalDate: Date;
}): Promise<IFinancePagination<DailyEarnings>> => {
  try {
    const initialDateFormatted = moment(initialDate).format('YYYY-MM-DD') + 'T00:00:00Z';
    const finalDateFormatted = moment(finalDate).format('YYYY-MM-DD') + 'T00:00:00Z';

    const wallets = walletIds ? `&walletIds=${walletIds}` : '';

    const { data } = await financialMarketInstance.get(
      `daily-operations/reports/summarized?initialDate=${initialDateFormatted}&finalDate=${finalDateFormatted}${wallets}`,
    );
    return data;
  } catch (error) {
    const errorMessage: Record<string, string> = {
      [ERROR_STATUS_MESSAGE.NOT_FOUND]: 'Ganhos diários não encontrados',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
    };

    throw new Error(
      errorMessage[(error as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};

export function customGenerateToQueryString({
  filters,
  page,
  size,
}: {
  filters?: ColumnFiltersState;
  page: number;
  size: number;
}) {
  let queryString = '';

  filters?.forEach((item: Record<string, any>) => {
    if (item.id.includes('custody_filtered')) {
      queryString += `custody_date=${item.value}&`;
    }

    if (item.id.includes('contract_type')) {
      queryString += `recurring_withdrawal=${item.value}&`;
    }

    if (item.id.includes('contract_id')) {
      queryString += `identification_number=${item.value}&`;
    }

    if (item.id.includes('client_name')) {
      const filter = item.id.replace('client_name', '');
      queryString += `$client.name${filter}$=${item.value}&`;
    }

    if (item.id.includes('client_code')) {
      queryString += `$client.code$=${item.value}&`;
    }

    if (item.id.includes('contract_profitability')) {
      queryString += `$profitability_group.percentage$=${item.value}&`;
    }

    if (item.id.includes('orderBy')) {
      queryString += `orderBy=${normalizeOrderBy(item.value)}&`;
    }
  });

  return queryString ? `?${queryString}&page=${page}&size=${size}` : `?page=${page}&size=${size}`;
}

const normalizeOrderBy = (items: string) => {
  if (items.includes('contract_id')) {
    items = items.replace('contract_id', 'identification_number');
  }

  if (items.includes('client_name')) {
    items = items.replace('client_name', 'client", "name');
  }

  if (items.includes('client_code')) {
    items = items.replace('client_code', 'client", "code');
  }

  if (items.includes('contract_profitability')) {
    items = items.replace('contract_profitability', 'profitability_group", "percentage');
  }

  if (items.includes('contract_type')) {
    items = items.replace('contract_type', 'recurring_withdrawal');
  }

  return items;
};
