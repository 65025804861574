import React from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import { moneyTransform } from '@helpers/transforms/money';
import moment from 'moment';

export const buildColumns = (
  isAdmin: boolean,
  onApprove: (id: string, status: string, clientID: string, contractID: string) => void,
) => {
  const columnHelper = createColumnHelper();

  return [
    columnHelper.accessor('contracts_transactions.client', {
      cell: ({ row, getValue }: any) => (
        <h5 className="font-size-14 mb-1">
          <Link to={`/clients/${getValue()?.id}`} className="text-dark">
            {getValue()?.name}
          </Link>
        </h5>
      ),
      header: 'Cliente',
      size: 20,
      meta: {
        isJoinColumn: true,
        filterAlias: 'contracts_transactions.client.name',
      },
    }),
    columnHelper.accessor('contracts_transactions.contract', {
      cell: ({ row, getValue }: any) => (
        <h5 className="font-size-14 mb-1">
          <Link to={`/contracts/${getValue()?.contract?.id}`} className="text-dark">
            {getValue()?.profitability_group?.description} /{' '}
            {getValue()?.profitability_group?.percentage.replace('.', ',')}%
          </Link>
        </h5>
      ),
      header: 'Grupo de rentabilidade',
      size: 20,
      meta: {
        disableFilterType: true,
      },
    }),
    columnHelper.accessor('value', {
      cell: ({ getValue }: any) => (
        <h5 className="font-size-14 mb-1">
          <Link to="#" className={classnames('text-dark', 'negative-value')}>
            {moneyTransform(getValue())}
          </Link>
        </h5>
      ),
      header: 'Valor',
      size: 10,
      meta: {
        disableFilter: true,
      },
    }),
    columnHelper.accessor('created_at', {
      cell: ({ getValue }: any) => (
        <p className="text-muted mb-0">{moment(getValue()).utcOffset(-6).format('DD/MM/YYYY')}</p>
      ),
      header: 'Data solicitação',
      size: 10,
      meta: {
        filterType: 'date_range',
        disableFilterType: true,
      },
    }),
    columnHelper.accessor('expected_at', {
      cell: ({ getValue }: any) => (
        <p className="text-muted mb-0">{moment(getValue()).utcOffset(6).format('DD/MM/YYYY')}</p>
      ),
      header: 'Data limite retirada',
      size: 10,
      meta: {
        filterType: 'date_range',
        disableFilterType: true,
      },
    }),
    columnHelper.accessor('actions', {
      cell: ({ row }: any) => {
        if (!isAdmin) {
          return;
        }

        return (
          <div className="d-flex gap-3">
            <Button
              color="success"
              outline
              size="sm"
              onClick={() => {
                onApprove(
                  row.original.id,
                  row.original.contracts_transactions_id,
                  row.original.contracts_transactions.client.id,
                  row.original.contracts_transactions.contract.id,
                );
              }}
            >
              <i className="mdi mdi-check font-size-14" id="edittooltip" />
              Aprovar
            </Button>
          </div>
        );
      },
      header: 'Ação',
      enableSorting: false,
      size: 10,
      meta: {
        disableFilter: true,
      },
    }),
  ];
};

export const buildConfirmedColumns = (isAdmin: boolean) => {
  const columnHelper = createColumnHelper();

  return [
    columnHelper.accessor('contracts_transactions.client', {
      cell: ({ row, getValue }: any) => (
        <h5 className="font-size-14 mb-1">
          <Link to={`/clients/${getValue()?.id}`} className="text-dark">
            {getValue()?.name}
          </Link>
        </h5>
      ),
      header: 'Cliente',
      size: 20,
      meta: {
        isJoinColumn: true,
        filterAlias: 'contracts_transactions.client.name',
      },
    }),
    columnHelper.accessor('contracts_transactions.contract', {
      cell: ({ row, getValue }: any) => (
        <h5 className="font-size-14 mb-1">
          <Link to={`/contracts/${getValue()?.contract?.id}`} className="text-dark">
            {getValue()?.profitability_group?.description} /{' '}
            {getValue()?.profitability_group?.percentage.replace('.', ',')}%
          </Link>
        </h5>
      ),
      header: 'Grupo de rentabilidade',
      size: 20,
      meta: {
        disableFilterType: true,
      },
    }),
    columnHelper.accessor('value', {
      cell: ({ getValue }: any) => (
        <h5 className="font-size-14 mb-1">
          <Link to="#" className={classnames('text-dark', 'negative-value')}>
            {moneyTransform(getValue())}
          </Link>
        </h5>
      ),
      header: 'Valor',
      size: 10,
      meta: {
        disableFilter: true,
      },
    }),
    columnHelper.accessor('created_at', {
      cell: ({ getValue }: any) => (
        <p className="text-muted mb-0">{moment(getValue()).utcOffset(6).format('DD/MM/YYYY')}</p>
      ),
      header: 'Data solicitação',
      size: 10,
      meta: {
        filterType: 'date_range',
        disableFilterType: true,
      },
    }),
    columnHelper.accessor('expected_at', {
      cell: ({ getValue }: any) => (
        <p className="text-muted mb-0">
          {getValue() ? moment(getValue()).utcOffset(9).format('DD/MM/YYYY') : ''}
        </p>
      ),
      header: 'Data limite retirada',
      size: 10,
      meta: {
        filterType: 'date_range',
        disableFilterType: true,
      },
    }),
    columnHelper.accessor('confirmed_at', {
      cell: ({ getValue }: any) => (
        <p className="text-muted mb-0">{moment(getValue()).utcOffset(6).format('DD/MM/YYYY')}</p>
      ),
      header: 'Data confirmação pagamento',
      size: 10,
      meta: {
        filterType: 'date_range',
        disableFilterType: true,
      },
    }),
    columnHelper.accessor('actions', {
      cell: ({ row }: any) => {
        if (!isAdmin) {
          return;
        }
        return (
          <div className="d-flex justify-content-end gap-3">
            <Link
              to={`/pending-withdrawals/${row.original.id}/pending-withdrawals-files`}
              className="btn btn-outline-warning btn-sm"
            >
              <i className="mdi mdi-eye font-size-14" id="edittooltip" /> Comprovantes
            </Link>
          </div>
        );
      },
      header: '',
      enableSorting: false,
      size: 15,
      meta: {
        disableFilter: true,
      },
    }),
  ];
};
