import { Tooltip } from 'reactstrap';
import styled from 'styled-components';

export const TransactionListContainer = styled.div`
  width: 100%;
`;
export const TransactionItemContainer = styled.div`
  display: flex;
  align-items: stretch;
`;
export const TransactionItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;

  padding: 16px;
  margin-bottom: 14px;

  background: none;
  border: none;

  transition: all 0.3s ease-in-out;

  &:hover {
    background: ${({ theme }) => theme.gray100};
  }
`;
export const TransactionDateWrapper = styled.div`
  display: flex;
  align-items: baseline;

  padding: 8px 0;
  border-bottom: 2px solid ${({ theme }) => theme.gray300};

  gap: 8px;
`;
export const TransactionDay = styled.span`
  font-size: 24px;
  font-weight: 600;

  color: ${({ theme }) => theme.gray400};
`;
export const TransactionMonth = styled.span`
  font-size: 16px;
  text-transform: capitalize;

  color: ${({ theme }) => theme.gray400};
`;
export const DescriptionCollumn = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  flex: 2;

  width: 100%;
`;

export const CategoryIconWrapper = styled.div`
  position: relative;
`;
export const CategoryIcon = styled.span<{ color?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 32px;
  height: 32px;

  margin-right: 8px;
  padding: 8px;
  font-size: 16px;

  border-radius: 100%;
  color: #fff;
  background: ${({ color, theme }) => color || theme.gray300};
`;
export const BadgeWarning = styled.span`
  position: absolute;
  bottom: 20px;
  right: 0px;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 16px;
  height: 16px;

  background: ${({ theme }) => theme.danger};
  color: #fff;
  border-radius: 100%;
  font-size: 10px;
  font-weight: 600;
  border: 2px solid ${({ theme }) => theme.customWhite};

  cursor: pointer;
`;

export const DescriptionContent = styled.div`
  display: flex;
  align-items: baseline;
  gap: 8px;
`;

export const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
export const DescriptionText = styled.span`
  font-size: 14px;
  color: ${({ theme }) => theme.gray900};
  text-align: left;
`;
export const RepeatCount = styled.span`
  color: ${({ theme }) => theme.gray900};
`;

export const DetailsCollumn = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: center;
  flex: 1;
`;

export const DetailsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  width: 20%;
`;

export const DetailsIcon = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  background: none;
  border: none;
  font-size: 16px;
  color: ${({ theme }) => theme.gray500};

  height: 24px;
  width: 24px;

  transition: all 0.3s ease-in-out;
  cursor: pointer;

  &:hover {
    filter: brightness(0.8);
  }
`;

export const DetailsTooltip = styled(Tooltip)`
  background: ${({ theme }) => theme.gray800};
`;

export const DetailsText = styled.span<{ color?: string }>`
  font-size: 12px;
  color: ${({ theme, color }) => color || theme.gray500};
`;

export const AccountName = styled.span`
  flex: 1;

  font-weight: 600;
  color: ${({ theme }) => theme.gray500};
  text-align: center;
`;

export const TransactionValueCollumn = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 1;
  gap: 16px;
`;

export const TransactionValue = styled.span`
  color: ${({ theme }) => theme.danger};
`;

export const ActionButtonIcon = styled.button`
  background: none;
  border: none;
  color: ${({ theme }) => theme.gray400};
  font-size: 20px;

  margin-bottom: 14px;

  transition: all 0.3s ease-in-out;
  cursor: pointer;

  &:hover {
    filter: brightness(0.8);
  }
`;

export const TagList = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;
export const TagItem = styled.span`
  position: relative;
  color: ${({ theme }) => theme.gray500};
  border-radius: 4px;
  font-size: 12px;

  &::before {
    content: '';
    position: absolute;
    left: -10px;
    top: 50%;
    transform: translateY(-50%);
    width: 6px;
    height: 6px;
    border-radius: 100%;
    background: ${({ theme }) => theme.gray400};
  }

  &:first-child::before,
  &:only-child::before {
    content: none;
  }
`;
