import React, { useEffect } from 'react';

import { useCategories } from '@modules/Finance/contexts/CategoriesContext';

import { Accordion } from '../Accordion';
import { CategoryActions } from '../CategoryActions';
import { SubCategoryList } from '../SubCategoryList';

import * as S from './styles';
import { LoadingSpinner } from '@modules/Finance/components/LoadingSpinner';

export const CategoryList = () => {
  // * [Contexts] * //
  const {
    categories,
    categoryActionType,
    //
    loadingCategories,
    //
    handleGetAllCategories,
  } = useCategories();

  // * [Variables] * //
  const noCategoriesFound = !loadingCategories && categories?.length === 0;

  // * [Effects] * //
  useEffect(() => {
    handleGetAllCategories && handleGetAllCategories();
  }, [categoryActionType]);

  return (
    <>
      {!loadingCategories &&
        categories?.map((category) => (
          <Accordion.Root key={category?.id} hasSubCategories={!!category?.subcategories}>
            <Accordion.Header>
              <S.CategoryBadgeContainer>
                <S.CategoryBadgeIcon color={category?.color} className={category?.icon} />
                <S.CategoryBadgeName>{category?.name}</S.CategoryBadgeName>
              </S.CategoryBadgeContainer>

              <CategoryActions category={category} />
            </Accordion.Header>

            <Accordion.Body>
              <SubCategoryList
                category={category}
                subCategories={category?.subcategories}
                isLastCategoryItem={categories[categories?.length - 1]?.id === category?.id}
              />
            </Accordion.Body>
          </Accordion.Root>
        ))}

      {noCategoriesFound && (
        <div className="d-flex justify-content-center mt-5">
          <p className="fs-5 text-center text-muted">Nenhuma categoria encontrada</p>
        </div>
      )}

      {loadingCategories && <LoadingSpinner />}
    </>
  );
};
