import styled from 'styled-components';

export const SummaryFinanceCard = styled.div`
  background: ${({ theme }) => theme.customWhite};

  padding: 16px;

  width: 20%;

  border-radius: 0.25rem;
`;
export const SummaryFinanceHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 16px;
`;
export const SumaryFinanceText = styled.span<{ color: string }>`
  display: flex;
  align-items: center;

  font-size: 14px;

  &::before {
    content: '';
    display: inline-block;
    width: 8px;
    height: 8px;
    margin-right: 4px;
    border-radius: 50%;
    background: ${({ color }) => color};
    opacity: 0.5;
  }
`;
export const SumaryFinanceIconBg = styled.div<{ color: string }>`
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;

  background: none;
  border-radius: 0.25rem;

  width: 28px;
  height: 28px;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: ${({ color }) => color};
    border-radius: 0.25rem;
    opacity: 0.1;
    z-index: 1;
  }
`;
export const SumaryFinanceIcon = styled.span<{ color: string }>`
  z-index: 2;

  opacity: 1;

  font-size: 14px;

  color: ${({ color }) => color};
`;
export const SummaryFinanceValue = styled.span`
  font-size: 24px;
  font-weight: 500;
`;
