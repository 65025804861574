import React from 'react';
import { Card, CardBody } from 'reactstrap';
import { moneyTransform } from '@helpers/transforms/money';

interface Props {
  selectedLines: any[];
}

export const SelectedSummary: React.FC<Props> = ({ selectedLines }) => {
  const totalAmount = selectedLines.reduce(
    (acc, curr) => acc + Number(curr.custody_filtered || 0),
    0,
  );

  if (selectedLines.length === 0) return null;

  return (
    <Card className="selected-summary position-fixed bottom-0 end-0 mb-4 me-4">
      <CardBody className="d-flex justify-content-between align-items-center">
        <div>
          <div className="fw-bold">
            Itens selecionados: <span className="text-primary">{selectedLines.length}</span>
          </div>
          <div>
            Total: <span className="text-success">{moneyTransform(totalAmount)}</span>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};
