import React, { useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  Row,
} from 'reactstrap';

import withRouter from '@common/withRouter';
import { Page } from '@component/common/Page';
import { useFormik } from 'formik';

import { Portuguese } from 'flatpickr/dist/l10n/pt';
import Flatpickr from 'react-flatpickr';
import classNames from 'classnames';
import moment from 'moment';
import { useMutation } from '@tanstack/react-query';
import { ITransactionExport } from '@modules/Contract/types';
import { exportAll } from '@modules/Contract/submodules/Transactions/services';

import { toast } from 'react-toastify';
import Spinners from '@component/common/Spinner';

const Transactions = () => {
  const [modal, setModal] = useState(false);
  const { mutate, isPending, isSuccess } = useMutation({
    mutationFn: (payload: ITransactionExport) => exportAll(payload),
    onSuccess: (fileUrl) => {
      setModal(true);

      const link = document.createElement('a');
      link.href = fileUrl;
      link.download = `transactions-export.xlsx`;
      link.click();
    },
    onError: () => {
      toast.error('Error exportar os arquivos!', {
        theme: 'colored',
        autoClose: 2000,
      });
    },
  });

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      transactionDate: '',
      type: '',
      paymentType: '',
      contractActive: '',
      recurringWithdrawal: '',
    },
    onSubmit: async (payload: any) => mutate(payload),
  });

  const clearFilters = () => {
    validation.resetForm();
  };

  return (
    <Page
      title="Relatórios"
      breadcrumb={{
        title: 'Relatórios',
        titleHref: '/reports',
        breadcrumbItem: 'Relatório de transações gerais',
      }}
    >
      {isPending && (
        <Modal isOpen={isPending} centered={true}>
          <div className="modal-content">
            <ModalBody className="px-4 py-5 text-center">
              <Spinners />
              <p className="text-muted font-size-16 mt-4">
                Aguarde enquanto a exportação das transações está sendo reprocessada...
              </p>
            </ModalBody>
          </div>
        </Modal>
      )}

      {isSuccess && modal && (
        <Modal isOpen={isSuccess && modal} centered={true}>
          <div className="modal-content modal-content-success">
            <ModalBody className="px-4 py-5 text-center">
              <button
                type="button"
                onClick={() => setModal(false)}
                className="btn-close position-absolute end-0 top-0 m-3"
              ></button>
              <i className="mdi mdi-check-circle"></i>
              <p className="text-muted font-size-16 mt-4">Transações exportadas com sucesso!</p>
            </ModalBody>
          </div>
        </Modal>
      )}

      <Row>
        <Col lg="12">
          <Card>
            <CardBody>
              <CardTitle>Relatório de transações gerais</CardTitle>

              <Form
                className=""
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <Row>
                  <FormGroup className="col-md-2">
                    <Label className="col-form-label col-lg-12">Tipo de transação</Label>
                    <Col lg={12}>
                      <Input
                        id="type"
                        name="type"
                        className="form-control"
                        type="select"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.type || ''}
                      >
                        <option value="">Nenhum</option>
                        <option value="APORTE">Aporte</option>
                        <option value="APORTE_LUCRO">Aporte do Lucro</option>
                        <option value="RETIRADA">Retirada</option>
                        <option value="RETIRADA_LUCRO">Retirada do Lucro</option>
                      </Input>
                    </Col>
                  </FormGroup>
                  <FormGroup className="col-md-2 ps-4">
                    <Label className="col-form-label col-lg-12">Tipo de pagamento</Label>
                    <Col lg={12}>
                      <Input
                        id="paymentType"
                        name="paymentType"
                        className="form-control"
                        type="select"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.paymentType || ''}
                      >
                        <option value="">Nenhum</option>
                        <option value="DINHEIRO">DINHEIRO</option>
                        <option value="PIX">PIX</option>
                        <option value="TED">TED</option>
                        <option value="BOLETO">BOLETO</option>
                        <option value="CHEQUE">CHEQUE</option>
                        <option value="A_NEGOCIAR">A NEGOCIAR</option>
                      </Input>
                    </Col>
                  </FormGroup>
                  <FormGroup className="col-md-4 ps-4">
                    <Label className="col-form-label col-lg-12">Selecione um período</Label>
                    <Col lg={12}>
                      <Flatpickr
                        className={classNames('form-control d-block')}
                        placeholder="Selecione um período"
                        onChange={(date) => {
                          validation.setFieldValue(
                            'transactionDate',
                            `${moment(date[0]).format('YYYY-MM-DD')};${moment(date[1]).format(
                              'YYYY-MM-DD',
                            )}`,
                          );
                        }}
                        value=""
                        options={{
                          mode: 'range',
                          altInput: true,
                          altFormat: 'F j, Y',
                          dateFormat: 'Y-m-d',
                          locale: Portuguese,
                        }}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup className="col-md-2 ps-4">
                    <Label className="col-form-label col-lg-12">Contrato ativo</Label>
                    <Col lg={12}>
                      <Input
                        id="contractActive"
                        name="contractActive"
                        className="form-control"
                        type="select"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.contractActive || ''}
                      >
                        <option value="">Nenhum</option>
                        <option value="true">Sim</option>
                        <option value="false">Não</option>
                      </Input>
                    </Col>
                  </FormGroup>
                  <FormGroup className="col-md-2 ps-4">
                    <Label className="col-form-label col-lg-12">Retirada recorrente</Label>
                    <Col lg={12}>
                      <Input
                        id="recurringWithdrawal"
                        name="recurringWithdrawal"
                        className="form-control"
                        type="select"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.recurringWithdrawal || ''}
                      >
                        <option value="">Nenhum</option>
                        <option value="true">Sim</option>
                        <option value="false">Não</option>
                      </Input>
                    </Col>
                  </FormGroup>
                </Row>
                <Row className="justify-content-end">
                  <Col lg={12} className="d-flex justify-content-end">
                    <Button color="danger" outline type="button" onClick={clearFilters}>
                      Limpar filtro
                    </Button>
                    <Button color="primary" type="submit" className="ms-4" disabled={isPending}>
                      Exportar transações
                    </Button>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Page>
  );
};

export default withRouter(Transactions);
