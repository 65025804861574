import React from 'react';
import classNames from 'classnames';
import { Alert, Col, FormGroup, Form, Row, Label, FormFeedback } from 'reactstrap';
import CreatableSelect from 'react-select/creatable';
import AsyncSelect from 'react-select/async';
import moment from 'moment';

import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/themes/material_blue.css';
import { Portuguese } from 'flatpickr/dist/l10n/pt';

import { IForm } from '@apptypes/form';
import { useSelector } from 'react-redux';
import instance from '@helpers/request/instance';
import { isNumber } from 'lodash';

const LinkPartnerForm = ({ showAlert, validation, loading, registrationError, type }: IForm) => {
  const { layoutModeTypes } = useSelector((state: any) => state.Layout);

  const handlePercentage = (data: any) => {
    let value = 0;

    if (data?.value) {
      value = isNumber(data.value) ? data.value : data.value.replace(',', '.');
    }

    validation.setFieldValue('partnerPercentage', Number(value));
  };

  function handleExpirationDate(date: any) {
    validation.setFieldValue('expirationDate', moment(date[0]).format('YYYY-MM-DD'));
  }

  const handlePartnerChange = (data: { value: string; label: string }) => {
    validation.setFieldValue('partner', data);
  };

  const getPartners = (_: string, callback: (data: any) => void) => {
    new Promise((resolve, reject) => {
      instance
        .get('/partners')
        .then((response) => {
          const data = response.data.items.map((partner: any) => ({
            value: partner.id,
            label: partner.name,
          }));

          callback(data);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  };

  return (
    <Form
      className="form-horizontal"
      onSubmit={(e) => {
        e.preventDefault();
        validation.handleSubmit();
        return false;
      }}
    >
      {showAlert ? <Alert color="success">Acessor vinculado com sucesso.</Alert> : null}

      {registrationError && registrationError ? (
        <Alert color="danger">{registrationError}</Alert>
      ) : null}

      <FormGroup className="mb-4" row>
        <Label className="col-form-label col-lg-2">Acessor</Label>
        <Col lg={10}>
          <AsyncSelect
            id="partner"
            name="partner"
            className={classNames('react-select-container', {
              'is-invalid': !!(
                validation.touched.partner?.value && validation.errors.partner?.value
              ),
            })}
            classNames={{
              singleValue: () => 'react-select-single-value',
              placeholder: () => 'react-select-placeholder',
            }}
            aria-placeholder="Selecione um acessor..."
            placeholder="Selecione um acessor..."
            cacheOptions
            isDisabled={type === 'edit'}
            defaultOptions
            loadOptions={getPartners}
            onChange={handlePartnerChange}
            value={validation.values.partner}
            theme={(theme) => {
              if (layoutModeTypes !== 'dark') {
                return theme;
              }

              return {
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: '#272727',
                  primary: '#272727',
                  neutral0: '#1E1E1E',
                },
              };
            }}
          />
          {validation.touched.partner?.value && validation.errors.partner?.value ? (
            <FormFeedback type="invalid">{validation.errors.partner?.value}</FormFeedback>
          ) : null}
        </Col>
      </FormGroup>

      <FormGroup className="mb-4" row>
        <Label className="col-form-label col-lg-2">Porcentagem</Label>
        <Col lg={10}>
          <CreatableSelect
            id="partnerPercentage"
            name="partnerPercentage"
            className={classNames('react-select-container', {
              'is-invalid': !!(
                validation.touched.partnerPercentage && validation.errors.partnerPercentage
              ),
            })}
            classNames={{
              singleValue: () => 'react-select-single-value',
              placeholder: () => 'react-select-placeholder',
            }}
            placeholder="Digite uma porcentagem"
            isClearable
            onChange={handlePercentage}
            value={{
              label: validation.values.partnerPercentage,
              value: validation.values.partnerPercentage,
            }}
            options={[
              { label: 0.5, value: 0.5 },
              { label: 0.3, value: 0.3 },
              { label: 0.2, value: 0.2 },
            ]}
            theme={(theme) => {
              if (layoutModeTypes !== 'dark') {
                return theme;
              }

              return {
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: '#272727',
                  primary: '#272727',
                  neutral0: '#1E1E1E',
                },
              };
            }}
            formatCreateLabel={(inputValue) => `Adicionar porcentagem: ${inputValue}`}
          />
          {validation.touched.partnerPercentage && validation.errors.partnerPercentage ? (
            <FormFeedback type="invalid">{validation.errors.partnerPercentage}</FormFeedback>
          ) : null}
        </Col>
      </FormGroup>

      <FormGroup className="mb-4" row>
        <Label className="col-form-label col-lg-2">Data de expiração do vinculo do acessor</Label>
        <Col lg={10}>
          <Flatpickr
            id="expirationDate"
            name="expirationDate"
            className={classNames('form-control d-block', {
              'is-invalid': !!(
                validation.touched.expirationDate && validation.errors.expirationDate
              ),
            })}
            placeholder="Selecione a data da expiração"
            onChange={handleExpirationDate}
            disabled={type === 'edit'}
            value={validation.values.expirationDate || ''}
            options={{
              altInput: true,
              altFormat: 'F j, Y',
              dateFormat: 'Y-m-d',
              locale: Portuguese,
              disable: [
                function (date: Date) {
                  return moment(date).diff(new Date(), 'days') < 0;
                },
              ],
            }}
          />
          {validation.touched.expirationDate && validation.errors.expirationDate ? (
            <FormFeedback type="invalid">{validation.errors.expirationDate}</FormFeedback>
          ) : null}
        </Col>
      </FormGroup>

      {validation.values.linkedAt && (
        <FormGroup className="mb-4" row>
          <Label className="col-form-label col-lg-2">Data da vinculação</Label>
          <Col lg={10}>
            <Flatpickr
              id="linkedAt"
              name="linkedAt"
              className={classNames('form-control d-block', {
                'is-invalid': !!(validation.touched.linkedAt && validation.errors.linkedAt),
              })}
              placeholder="Selecione a data da expiração"
              onChange={handleExpirationDate}
              disabled={type === 'edit'}
              value={validation.values.linkedAt || ''}
              options={{
                altInput: true,
                altFormat: 'F j, Y',
                dateFormat: 'Y-m-d',
                locale: Portuguese,
                disable: [
                  function (date: Date) {
                    return moment(date).diff(new Date(), 'days') < 0;
                  },
                ],
              }}
            />
            {validation.touched.linkedAt && validation.errors.linkedAt ? (
              <FormFeedback type="invalid">{validation.errors.linkedAt}</FormFeedback>
            ) : null}
          </Col>
        </FormGroup>
      )}

      <Row className="justify-content-end">
        <Col lg={10}>
          {type !== 'edit' && (
            <button className="btn btn-primary btn-block" type="submit" disabled={loading}>
              Criar vinculo entre acessor e contrato
            </button>
          )}
        </Col>
      </Row>
    </Form>
  );
};

export default LinkPartnerForm;
