import React from 'react';

import * as S from './styles';

import { ISummaryFinanceCardProps } from './types';

export const SummaryFinanceCard = ({ name, icon, value, color }: ISummaryFinanceCardProps) => {
  return (
    <S.SummaryFinanceCard>
      <S.SummaryFinanceHeader>
        <S.SumaryFinanceText color={color}>{name}</S.SumaryFinanceText>
        <S.SumaryFinanceIconBg color={color}>
          <S.SumaryFinanceIcon className={icon} color={color} />
        </S.SumaryFinanceIconBg>
      </S.SummaryFinanceHeader>

      <S.SummaryFinanceValue>{value}</S.SummaryFinanceValue>
    </S.SummaryFinanceCard>
  );
};
