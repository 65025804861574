import React from 'react';
import {
  Alert,
  Input,
  Label,
  Form,
  FormFeedback,
  FormGroup,
  Col,
  Row,
  InputGroup,
} from 'reactstrap';
import { IWalletFormProps } from '../../types';
import { NumericFormat } from 'react-number-format';

export const WalletUpdateForm = ({
  loading = false,
  registrationError,
  showAlert,
  type = 'create',
  validation,
}: IWalletFormProps) => {
  return (
    <Form
      className="form-horizontal"
      onSubmit={(e) => {
        e.preventDefault();
        validation.handleSubmit();
        return false;
      }}
    >
      {showAlert ? (
        <Alert color="success">{type === 'create' ? 'Cadastrado' : 'Editado'} com sucesso.</Alert>
      ) : null}

      {registrationError && registrationError ? (
        <Alert color="danger">{registrationError}</Alert>
      ) : null}

      <FormGroup className="mb-4" row>
        <Label className="col-form-label col-lg-2">Ativo</Label>
        <Col lg={10}>
          <Input
            id="active"
            name="active"
            className="form-control"
            type="select"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.active || ''}
            invalid={!!(validation.touched.active && validation.errors.active)}
          >
            <option value="">Selecione uma opção</option>
            <option value="true">Ativado</option>
            <option value="false">Desativado</option>
          </Input>
          {validation.touched.active && validation.errors.active ? (
            <FormFeedback type="invalid">{validation.errors.active}</FormFeedback>
          ) : null}
        </Col>
      </FormGroup>

      <FormGroup className="mb-4" row>
        <Label className="col-form-label col-lg-2">Saldo</Label>
        <Col lg={10}>
          <InputGroup>
            <NumericFormat
              id="value"
              name="value"
              className="form-control"
              decimalSeparator=","
              thousandSeparator="."
              placeholder="Digite um saldo"
              prefix="R$ "
              value={validation.values.value ? validation.values.value : ''}
              decimalScale={2}
              onValueChange={(data: any) => validation.setFieldValue('value', data.value)}
            />
          </InputGroup>
          {validation.touched.value && validation.errors.value ? (
            <FormFeedback type="invalid">{validation.errors.value}</FormFeedback>
          ) : null}
        </Col>
      </FormGroup>

      <FormGroup className="mb-4" row>
        <Label className="col-form-label col-lg-2">Nome</Label>
        <Col lg={10}>
          <Input
            name="name"
            type="text"
            placeholder="Digite o nome"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.name || ''}
            invalid={!!(validation.touched.name && validation.errors.name)}
          />
          {validation.touched.name && validation.errors.name ? (
            <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
          ) : null}
        </Col>
      </FormGroup>

      <FormGroup className="mb-4" row>
        <Label className="col-form-label col-lg-2">Tipo de conta</Label>
        <Col lg={10}>
          <Input
            id="type"
            name="type"
            className="form-control"
            type="select"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.type || ''}
            invalid={!!(validation.touched.type && validation.errors.type)}
          >
            <option value="">Selecione um tipo de conta corrente</option>
            <option value="CC">Conta corrente</option>
            <option value="CP">Conta poupança</option>
          </Input>
          {validation.touched.type && validation.errors.type ? (
            <FormFeedback type="invalid">{validation.errors.type}</FormFeedback>
          ) : null}
        </Col>
      </FormGroup>

      <Row className="justify-content-end">
        <Col lg={10}>
          <button className="btn btn-primary btn-block" type="submit" disabled={loading}>
            {loading ? <>Salvando...</> : `${type === 'create' ? 'Cadastrar' : 'Editar'}`}
          </button>
        </Col>
      </Row>
    </Form>
  );
};
