import React from 'react';
import Reports from '@modules/Reports/pages';
import Custody from '@modules/Reports/pages/custody';
import Transactions from '@modules/Reports/pages/transactions';
import CustodyDetailed from '../pages/custodyDetailed';
import DailyEarnings from '../pages/dailyEarnings';

export const reportsRoutes = [
  { path: 'reports', component: <Reports /> },
  { path: 'reports/custody', component: <Custody /> },
  { path: 'reports/custody/detailed', component: <CustodyDetailed /> },
  { path: 'reports/transactions', component: <Transactions /> },
  { path: 'reports/daily-earnings', component: <DailyEarnings /> },
];
