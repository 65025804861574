import { InstanceError } from '@apptypes/instance';
import { UNEXPECTED_ERROR } from '@helpers/constants';
import { ERROR_STATUS_MESSAGE } from '@helpers/request/constants';
import { financialApiInstance as instance } from '@helpers/request/instance';
import {
  IMoneyTransfer,
  IWallet,
  IWalletCreate,
  IWalletForm,
  IWalletPagination,
  IWalletUpdate,
} from '../types';

export const getByID = async (walletId: string): Promise<IWallet> => {
  try {
    const { data } = await instance.get(`/api/v1/wallets/${walletId}`);
    return data;
  } catch (error) {
    const errorMessage: Record<number, string> = {
      [ERROR_STATUS_MESSAGE.NOT_FOUND]: 'Carteira não encontrada',
      [ERROR_STATUS_MESSAGE.UNAUTHORIZED]: 'Você não tem permissão para visualizar a carteira',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
    };

    throw new Error(
      errorMessage[(error as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};

export const getAll = async ({
  page,
  limit,
  filters = '',
}: {
  page: number;
  limit: number;
  filters: string;
}): Promise<IWalletPagination> => {
  try {
    const { data } = await instance.get(
      `/api/v1/wallets?page=${page}&limit=${limit}${filters ? `&filters=${filters}` : ''}`,
    );
    return data;
  } catch (error) {
    const errorMessage: Record<number, string> = {
      [ERROR_STATUS_MESSAGE.NOT_FOUND]: 'Carteira não encontrada',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
    };

    throw new Error(
      errorMessage[(error as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};

export const create = async (payload: IWalletForm): Promise<IWallet> => {
  try {
    const body: IWalletCreate = {
      active: payload.active === 'true',
      name: payload.name,
      type: payload.type,
      balance: Number(payload.balance) || 0,
      creditCard: {
        issuer: payload.issuer,
        number: payload.number,
        holderName: payload.holderName,
        firstDueDate: `${payload.firstDueDate}T00:00:00.000Z`,
        dueDay: payload.dueDay,
        closeDay: payload.closeDay,
      },
    };

    const { data } = await instance.post('/api/v1/wallets', body);
    return data;
  } catch (error) {
    const errorMessage: Record<number, string> = {
      [ERROR_STATUS_MESSAGE.BAD_REQUEST]: 'Não foi possível criar a carteira',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: UNEXPECTED_ERROR,
    };

    throw new Error(
      errorMessage[(error as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};

export const transferMoney = async (payload: IMoneyTransfer): Promise<IWallet> => {
  try {
    const body = {
      walletFromId: payload.walletFrom.value,
      walletToId: payload.walletTo.value,
      amount: payload.amount,
    };

    const { data } = await instance.post('/api/v1/wallets/transfer', body);
    return data;
  } catch (error) {
    const errorMessage: Record<number, string> = {
      [ERROR_STATUS_MESSAGE.BAD_REQUEST]: 'Não foi possível criar a carteira',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: UNEXPECTED_ERROR,
    };

    throw new Error(
      errorMessage[(error as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};

export const update = async (
  walletId: string,
  userId: string,
  payload: IWalletUpdate,
): Promise<IWalletUpdate> => {
  try {
    const body = {
      active: payload.active === 'true',
      balance: Number(payload.value) || 0,
      name: payload.name,
      type: payload.type,
    };

    const { data } = await instance.put(`/api/v1/wallets/${walletId}`, body);
    return data;
  } catch (error) {
    const errorMessage: Record<number, string> = {
      [ERROR_STATUS_MESSAGE.BAD_REQUEST]: 'Não foi possível editar a carteira',
      [ERROR_STATUS_MESSAGE.NOT_FOUND]: 'Carteira não encontrada',
      [ERROR_STATUS_MESSAGE.UNAUTHORIZED]: 'Você não tem permissão para editar a carteira',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
    };

    throw new Error(
      errorMessage[(error as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};

export const deleteByID = async (walletId: string) => {
  try {
    const { data } = await instance.delete(`/api/v1/wallets/${walletId}`);
    return data;
  } catch (error) {
    const errorMessage: Record<number, string> = {
      [ERROR_STATUS_MESSAGE.BAD_REQUEST]: 'Não foi possível deletar a carteira',
      [ERROR_STATUS_MESSAGE.UNAUTHORIZED]: 'Você não tem permissão para deletar a carteira',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
    };

    throw new Error(
      errorMessage[(error as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};
