import React from 'react';
import classNames from 'classnames';
import { flexRender, HeaderGroup } from '@tanstack/react-table';
import { THeadFilter } from './THeadFilter';

interface TheadProps {
  dragActiveColumn?: number;
  dragOver?: number;
  enableFilters: boolean;
  disableColumnFilters?: boolean;
  enableDragAndDrop: boolean;
  headers: Array<HeaderGroup<unknown>>;
  onDragStart: (event: React.DragEvent<HTMLDivElement>) => void;
  onDragOver: (event: React.DragEvent<HTMLDivElement>) => void;
  onDragLeave: (event: React.DragEvent<HTMLDivElement>) => void;
  onDrop: (event: React.DragEvent<HTMLDivElement>) => void;
  setFilterTypes: (data: Record<string, any>) => void;
}

export const Thead = ({
  dragActiveColumn,
  dragOver,
  enableFilters,
  enableDragAndDrop,
  disableColumnFilters,
  headers,
  onDragStart,
  onDragOver,
  onDragLeave,
  onDrop,
  setFilterTypes,
}: TheadProps) => (
  <thead className={classNames('table-light')}>
    {headers.map((headerGroup) => (
      <tr key={headerGroup.id}>
        {headerGroup.headers.map((header) => {
          const meta = header.column.columnDef?.meta as Record<string, any>;

          return (
            <th
              colSpan={header.colSpan}
              key={header.id}
              className={classNames({
                'table-draggable': enableDragAndDrop,
                'table-draggable-active': dragActiveColumn === header.index,
                'table-drag-over': dragOver === header.index,
              })}
              style={{
                width: header.column.columnDef.size
                  ? `${header.column.columnDef.size}%`
                  : undefined,
              }}
              data-column-index={header.index}
              draggable={enableDragAndDrop}
              onDragStart={onDragStart}
              onDragOver={onDragOver}
              onDragLeave={onDragLeave}
              onDragEnd={onDrop}
              onDrop={onDrop}
            >
              <div
                className="d-flex"
                onClick={
                  !enableDragAndDrop && !meta?.disableOrderBy
                    ? header.column.getToggleSortingHandler()
                    : undefined
                }
              >
                {flexRender(header.column.columnDef.header, header.getContext())}
                {!enableDragAndDrop ? (
                  {
                    asc: <span className="ms-2 mdi mdi-arrow-up-bold-outline"></span>,
                    desc: <span className="ms-2 mdi mdi-arrow-down-bold-outline"></span>,
                  }[header.column.getIsSorted() as string] ?? null
                ) : (
                  <i className="mdi mdi-drag ms-2"></i>
                )}
              </div>
              {enableFilters && !disableColumnFilters && (
                <THeadFilter setFilterTypes={setFilterTypes} column={header.column} />
              )}
            </th>
          );
        })}
      </tr>
    ))}
  </thead>
);
