import React from 'react';
import { ModalBody, ModalHeader } from 'reactstrap';
import * as Yup from 'yup';
import { DailyOperationCloseForm } from '../DailyOperationCloseForm';
import { useMutation } from '@tanstack/react-query';
import { IDailyOperationClose } from '@modules/DailyOperations/types';
import { close } from '@modules/DailyOperations/services';
import { useFormik } from 'formik';
import moment from 'moment';
import {
  TYPE_FINAL_DAY_TRADE_AMOUNT,
  TYPE_FINAL_SWING_TRADE_AMOUNT,
  TYPE_REFERENCE_DATE,
  TYPE_WALLET,
} from '@modules/DailyOperations/constants';

const CloseContent = ({ toogleModal }: { toogleModal: () => void }) => {
  const { mutate, error, isPending, isSuccess } = useMutation({
    mutationFn: (payload: IDailyOperationClose) => close(payload),
    onSuccess: () => {
      toogleModal();
      validation.resetForm();
    },
  });

  const validation = useFormik({
    enableReinitialize: false,
    initialValues: {
      wallet: {
        value: '',
        label: '',
      },
      finalDayTradeAmount: 0,
      finalSwingTradeAmount: 0,
      referenceDate: moment(new Date()).format('YYYY-MM-DDTHH:mm:ss'),
    },
    validationSchema: Yup.object({
      wallet: Yup.object({
        value: Yup.string().required(TYPE_WALLET),
      }),
      finalDayTradeAmount: Yup.string().required(TYPE_FINAL_DAY_TRADE_AMOUNT),
      finalSwingTradeAmount: Yup.string().required(TYPE_FINAL_SWING_TRADE_AMOUNT),
      referenceDate: Yup.string().required(TYPE_REFERENCE_DATE),
    }),
    onSubmit: (payload: IDailyOperationClose) => mutate(payload),
  });

  return (
    <>
      <ModalHeader>Fechar operação</ModalHeader>
      <ModalBody>
        <DailyOperationCloseForm
          validation={validation}
          loading={isPending}
          registrationError={error?.message}
          showAlert={isSuccess}
        />
      </ModalBody>
    </>
  );
};

export default CloseContent;
