import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { toast } from 'react-toastify';
import { useMutation, useQuery } from '@tanstack/react-query';

import { IWithRouter } from '@apptypes/WithRouter';
import { Page } from '@component/common/Page';
import TableContainer from '@common/NewTableContainer';
import withRouter from '@common/withRouter';
import { buildColumns } from '@modules/Wallets/components/Table/constants';
import { deleteByID, getAll } from '@modules/Wallets/services';
import TransferModal from '../components/TransferModal';

const Wallets = ({ router, page, size, setSearchParams }: IWithRouter) => {
  const [modal, setModal] = useState(false);
  const [filters, setFilters] = useState<string>('');
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [deleteWalletID, setDeleteWalletID] = useState<string | undefined>();

  const { user }: any = useSelector((state: any) => state.Profile);
  const { data, isFetching, refetch } = useQuery({
    queryKey: ['getAllWallets', page, size, filters],
    queryFn: () => getAll({ page, limit: size, filters }),
    initialData: {
      page: {
        limit: 10,
        page: 1,
        totalRows: 0,
        totalPages: 0,
      },
      wallets: [],
    },
    retry: 1,
  });

  const deleteMutation = useMutation({
    mutationFn: () => {
      if (deleteWalletID) {
        return deleteByID(deleteWalletID);
      }

      return Promise.resolve();
    },
    onSuccess: () => {
      toast.success('Carteira deletado com sucesso!', {
        theme: 'colored',
        autoClose: 1500,
      });

      setSearchParams({ page: '1' });
      setDeleteModal(false);
      refetch();
    },
  });

  const handleDeleteWalletModal = (brokerAccountId: string) => {
    setDeleteWalletID(brokerAccountId);
    setDeleteModal(true);
  };

  return (
    <Page
      title="Carteiras"
      breadcrumb={{
        title: 'Carteiras',
        titleHref: '/wallets',
        breadcrumbItem: 'Listar carteiras',
      }}
    >
      <Row>
        <Col lg="12">
          <Card>
            <CardBody>
              <TableContainer
                moduleName="wallets"
                columns={buildColumns(user?.is_admin, handleDeleteWalletModal)}
                currentPage={page}
                customPageSize={size}
                data={data.wallets}
                loading={isFetching}
                totalItems={data.page.totalPages}
                deleteModal={{
                  isOpen: deleteModal,
                  loading: isFetching,
                  onDeleteClick: deleteMutation.mutate,
                  onCloseClick: () => setDeleteModal(false),
                }}
                handleFilters={setFilters}
                setSearchParams={setSearchParams}
                refetch={refetch}
                isAddRegister
                isPagination
                isShowingPageLength
                isDelete
                handleAddRegisterClick={() => router.navigate('/wallets/create')}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>

      <TransferModal modal={modal} setModal={setModal} refetch={refetch} />

      <div className="action-button">
        <button
          type="button"
          className="action-button__button action-button__button--transfer"
          onClick={() => setModal(true)}
        >
          <i className="mdi mdi-swap-horizontal"></i>
        </button>
      </div>
    </Page>
  );
};

export default withRouter(Wallets);
