import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import styled from 'styled-components';

// * [Modal Header] * //
export const TransactionsModalHeader = styled(ModalHeader)`
  border-bottom: none;
`;

// * [Modal Body] * //
export const FilterAccordion = styled(Accordion)``;

export const FilterAccordionItem = styled(AccordionItem)``;

export const FilterAccordionHeader = styled(AccordionHeader)``;

export const FilterAccordionBody = styled(AccordionBody)``;

// * [Modal Footer] * //
export const TransactionsModalFooter = styled(ModalFooter)`
  display: flex;
  justify-content: center;

  padding-bottom: 32px;

  border-top: none;
`;
