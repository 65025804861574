import React from 'react';
import {
  Alert,
  Button,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  InputGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from 'reactstrap';
import * as Yup from 'yup';
import { useMutation } from '@tanstack/react-query';
import { useFormik } from 'formik';
import AsyncSelect from 'react-select/async';
import { useSelector } from 'react-redux';
import { getCookie } from '@helpers/cookies';
import { AUTH_COOKIE_NAME } from '@helpers/constants';
import { IMoneyTransfer, IWallet } from '@modules/Wallets/types';
import { financialApiInstance } from '@helpers/request/instance';
import { NumericFormat } from 'react-number-format';
import { toast } from 'react-toastify';
import { transferMoney } from '@modules/Wallets/services';

const TransferModal = ({ modal, setModal, refetch }: any) => {
  const { mutate, error, isPending } = useMutation({
    mutationFn: (payload: IMoneyTransfer) => transferMoney(payload),
    onSuccess: () => {
      toast.success('Transferência realizada com sucesso!', {
        theme: 'colored',
        autoClose: 750,
        onClose: () => {
          refetch();
          setModal(false);
          validation.resetForm();
        },
      });
    },
    onError: () => {
      toast.error('Falha ao realizar a transferência!', {
        theme: 'colored',
        autoClose: 1000,
      });
    },
  });

  const { layoutModeTypes } = useSelector((state: any) => state.Layout);

  const validation = useFormik({
    enableReinitialize: false,
    initialValues: {
      walletFrom: {
        value: '',
        label: '',
      },
      walletTo: {
        value: '',
        label: '',
      },
      amount: 0,
    },
    validationSchema: Yup.object({
      amount: Yup.string().required('Valor é obrigatorio'),
    }),
    onSubmit: (payload: IMoneyTransfer) => mutate(payload),
  });

  const getWallets = (_: string, callback: (data: any) => void) => {
    new Promise((resolve, reject) => {
      financialApiInstance
        .get('/api/v1/wallets?page=1&limit=100', {
          headers: {
            authorization: `Bearer ${getCookie(AUTH_COOKIE_NAME)}`,
          },
        })
        .then((response) => {
          const data = response.data.wallets.map((wallet: IWallet) => ({
            value: wallet.id,
            label: wallet.name,
          }));

          callback(data);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  };

  return (
    <Modal isOpen={modal} toggle={() => setModal(false)}>
      <ModalHeader>Transferir saldo</ModalHeader>
      <ModalBody>
        <Form
          className="form-horizontal"
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          {error?.message ? <Alert color="danger">{error.message}</Alert> : null}

          <FormGroup>
            <Label className="col-form-label col-lg-12">Conta origem</Label>
            <Col lg={12}>
              <AsyncSelect
                name="walletFrom"
                className="react-select-container"
                classNames={{
                  singleValue: () => 'react-select-single-value',
                  placeholder: () => 'react-select-placeholder',
                }}
                aria-placeholder="Selecione uma carteira..."
                cacheOptions
                defaultOptions
                loadOptions={getWallets}
                onChange={(data: any) => {
                  validation.setFieldValue('walletFrom', data);
                }}
                value={validation.values.walletFrom}
                theme={(theme) => {
                  if (layoutModeTypes !== 'dark') {
                    return theme;
                  }

                  return {
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: '#272727',
                      primary: '#272727',
                      neutral0: '#1E1E1E',
                    },
                  };
                }}
              />
              {validation.touched.walletFrom && validation.errors.walletFrom ? (
                <FormFeedback type="invalid">{validation.errors.walletFrom as any}</FormFeedback>
              ) : null}
            </Col>
          </FormGroup>

          <FormGroup>
            <Label className="col-form-label col-lg-12">Conta destino</Label>
            <Col lg={12}>
              <AsyncSelect
                name="walletTo"
                className="react-select-container"
                classNames={{
                  singleValue: () => 'react-select-single-value',
                  placeholder: () => 'react-select-placeholder',
                }}
                aria-placeholder="Selecione uma carteira..."
                cacheOptions
                defaultOptions
                loadOptions={getWallets}
                onChange={(data: any) => {
                  validation.setFieldValue('walletTo', data);
                }}
                value={validation.values.walletTo}
                theme={(theme) => {
                  if (layoutModeTypes !== 'dark') {
                    return theme;
                  }

                  return {
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: '#272727',
                      primary: '#272727',
                      neutral0: '#1E1E1E',
                    },
                  };
                }}
              />
              {validation.touched.walletTo && validation.errors.walletTo ? (
                <FormFeedback type="invalid">{validation.errors.walletTo as any}</FormFeedback>
              ) : null}
            </Col>
          </FormGroup>

          <FormGroup>
            <Label className="col-form-label text-start col-lg-12">Valor</Label>
            <Col lg={12}>
              <InputGroup>
                <NumericFormat
                  id="amount"
                  name="amount"
                  className="form-control"
                  decimalSeparator=","
                  thousandSeparator="."
                  placeholder="Digite um valor"
                  prefix="R$ "
                  value={validation.values.amount ? validation.values.amount : ''}
                  decimalScale={2}
                  onValueChange={(data: any) =>
                    validation.setFieldValue('amount', Number(data.value))
                  }
                />
              </InputGroup>
              {validation.touched.amount && validation.errors.amount ? (
                <div className="d-flex pt-2">
                  <p className="text-danger font-size-12 mb-0">{validation.errors.amount}</p>
                </div>
              ) : null}
            </Col>
          </FormGroup>

          <Row className="justify-content-end">
            <Col lg={12} className="d-flex justify-content-end">
              <Button color="success" className="btn-block" type="submit" disabled={isPending}>
                {isPending ? <>Salvando...</> : 'Transferir saldo'}
              </Button>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default TransferModal;
