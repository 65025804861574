import React from 'react';
import { Modal } from 'reactstrap';
import CloseContent from './close';

interface IActionModal {
  router: any;
  isOpen: boolean;
  toogleModal: () => void;
}

const ActionModal = ({ router, isOpen, toogleModal }: IActionModal) => (
  <Modal isOpen={isOpen} toggle={toogleModal}>
    <CloseContent toogleModal={toogleModal} />
  </Modal>
);

export default ActionModal;
