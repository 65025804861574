import React from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import TableActions from '@component/common/TableActions';
import { Badge } from 'reactstrap';
import { moneyTransform } from '@helpers/transforms/money';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

export const buildColumns = (isAdmin: boolean, handleDeleteWalletModal: (data: string) => void) => {
  const columnHelper = createColumnHelper();

  return [
    columnHelper.accessor('active', {
      cell: ({ row, getValue }: any) => (
        <>
          {getValue() ? (
            <Badge color="success" className="font-size-12" pill>
              Ativado
            </Badge>
          ) : (
            <Badge color="danger" className="font-size-12" pill>
              Desativado
            </Badge>
          )}
        </>
      ),
      header: 'Status',
      size: 10,
      meta: {
        filterType: 'select',
        filterOptions: [
          { value: '', label: 'Selecione...' },
          { value: 'true', label: 'Sim' },
          { value: 'false', label: 'Não' },
        ],
      },
    }),
    columnHelper.accessor('balance', {
      cell: (data: any) => (
        <Link to={`/wallets/${data.row.original.id}`}>
          <h5
            className={classNames('font-size-14 mb-1', {
              'text-success': data.getValue() >= 0,
              'text-danger': data.getValue() < 0,
            })}
          >
            {moneyTransform(data.getValue())}
          </h5>
        </Link>
      ),
      header: 'Saldo',
      size: 17,
      meta: {
        disableFilter: true,
      },
    }),
    columnHelper.accessor('name', {
      cell: (data: any) => (
        <Link to={`/wallets/${data.row.original.id}`}>
          <h5 className={classNames('font-size-14 mb-1 text-muted')}>{data.getValue()}</h5>
        </Link>
      ),
      header: 'Nome',
      size: 50,
    }),
    columnHelper.accessor('type', {
      cell: (data: any) => (
        <Link to={`/wallets/${data.row.original.id}`}>
          <h5 className={classNames('font-size-14 mb-1 text-muted')}>
            {data.getValue() === 'CC' ? 'Conta corrente' : 'Conta poupança'}
          </h5>
        </Link>
      ),
      header: 'Tipo da conta',
      size: 15,
      meta: {
        filterType: 'select',
        filterOptions: [
          { value: '', label: 'Selecione...' },
          { value: 'CC', label: 'Conta corrente' },
          { value: 'CP', label: 'Conta poupança' },
        ],
      },
    }),
    columnHelper.accessor('actions', {
      cell: ({ row }: any) => (
        <TableActions
          isAdmin={isAdmin}
          editLink={`/wallets/${row.original.id}`}
          itemID={row.original.id}
          onDeleteModal={handleDeleteWalletModal}
        />
      ),
      header: 'Ação',
      enableSorting: false,
      size: 10,
      meta: {
        disableFilter: true,
      },
    }),
  ];
};
