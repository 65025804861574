import React from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';

import { Page } from '@component/common/Page';
import withRouter from '@common/withRouter';

import { TransactionTypeEnum } from '@modules/Finance/ultis/transactions/enums';
import { useCategories } from '@modules/Finance/contexts/CategoriesContext';
import { ITab } from '@modules/Finance/components/Tabs/types';
import { Tabs } from '@modules/Finance/components/Tabs';

import { ManageCategoryModal } from './components/ManageCategoryModal';
import { CategoryList } from './components/CategoryList';

import * as S from './styles';

const Categories = () => {
  // * [Contexts] * //
  const {
    showManageCategoryModal,
    categoryActionType,
    //
    setCategoryActionType,
    //
    handleToggleManageCategoryModal,
  } = useCategories();

  // * [Objects] * //
  const CATEGORY_TEXTS = {
    [TransactionTypeEnum.Expense]: 'Criar Categoria de Despesa',
    [TransactionTypeEnum.Income]: 'Criar Categoria de Receita',
  };

  const TAB_OPTIONS: Array<ITab<TransactionTypeEnum>> = [
    { value: TransactionTypeEnum.Expense, label: 'Despesas' },
    { value: TransactionTypeEnum.Income, label: 'Receitas' },
  ];

  // * [Variables] * //
  const CREATE_CATEGORY_TEXT = CATEGORY_TEXTS[categoryActionType];

  return (
    <Page
      title="Categorias"
      breadcrumb={{
        title: 'Categorias',
        titleHref: '/categories',
        breadcrumbItem: 'Listar categorias',
      }}
    >
      <Row>
        <Col lg="12">
          <Card>
            <CardBody>
              <S.CategoryHeader>
                <S.CategoryTitle>Gerenciar Categorias</S.CategoryTitle>

                <S.CategoryCreateButton color="primary" onClick={handleToggleManageCategoryModal}>
                  <S.CategoryIcon className="mdi mdi-plus me-1" />
                  {CREATE_CATEGORY_TEXT}
                </S.CategoryCreateButton>
              </S.CategoryHeader>

              <Tabs
                activeTab={categoryActionType}
                toggleTab={setCategoryActionType}
                tabs={TAB_OPTIONS}
              />

              <CategoryList />
            </CardBody>
          </Card>

          {showManageCategoryModal && (
            <ManageCategoryModal
              title={CREATE_CATEGORY_TEXT}
              showModal={showManageCategoryModal}
              type={categoryActionType}
              toggle={handleToggleManageCategoryModal}
            />
          )}
        </Col>
      </Row>
    </Page>
  );
};

export default withRouter(Categories);
