import React, { useState } from 'react';
import { moneyTransform } from '@helpers/transforms/money';
import { useQuery } from '@tanstack/react-query';
import classNames from 'classnames';
import moment from 'moment';
import { getOpenedPayments } from '@modules/Partners/submodules/Payments/services';
import { Input, Modal, ModalBody, Table } from 'reactstrap';

interface props {
  show: boolean;
  icon: string;
  iconColor?: string;
  actionDescription: string;
  actionButtonText: string;
  isLoading?: boolean;
  loadingText?: string;
  actionButtonColor?: string;
  partnerId: string;
  onActionClick: (payments: string[]) => void;
  onCloseClick: () => void;
}

const ActionModal = ({
  show,
  icon,
  iconColor = 'primary',
  actionDescription,
  actionButtonText,
  actionButtonColor = 'danger',
  isLoading = false,
  loadingText = 'Carregando...',
  partnerId,
  onActionClick,
  onCloseClick,
}: props) => {
  const [payments, setPayments] = useState<string[]>([]);
  const { data } = useQuery({
    queryKey: ['actionModal', partnerId],
    queryFn: () => getOpenedPayments(partnerId),
    initialData: { items: [], totalItems: 0 },
  });

  const handleClick = (value: string) => {
    const currentPayments = [...payments];
    const index = currentPayments.indexOf(value);

    if (index === -1) {
      currentPayments.push(value);
    } else {
      currentPayments.splice(index, 1);
    }

    setPayments(currentPayments);
  };

  const handleToggle = () => {
    if (payments.length > 0) {
      setPayments([]);

      return;
    }

    setPayments(data.items.map((item) => item.id));
  };

  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true}>
      <div className="modal-content">
        <ModalBody className="px-4 py-5 text-center">
          <button
            type="button"
            onClick={onCloseClick}
            className="btn-close position-absolute end-0 top-0 m-3"
          ></button>

          {isLoading ? (
            <p className="text-muted font-size-16">{loadingText}</p>
          ) : (
            <>
              <div className="avatar-sm mb-4 mx-auto">
                <div
                  className={`avatar-title bg-${iconColor} text-${iconColor} bg-opacity-10 font-size-20 rounded-3`}
                >
                  <i className={icon}></i>
                </div>
              </div>
              <p className="text-muted font-size-16 mb-4">{actionDescription}</p>

              <p className="text-muted font-size-14 mb-4 text-start">
                Selecione os pagamentos que deseja cancelar
              </p>
              <Table bordered borderless hover striped>
                <thead>
                  <tr>
                    <th>
                      <Input type="checkbox" onClick={handleToggle} checked={payments.length > 0} />
                    </th>
                    <th>Data</th>
                    <th>Valor</th>
                  </tr>
                </thead>
                <tbody>
                  {data.items.map((item) => (
                    <tr key={item.id}>
                      <td>
                        <Input
                          type="checkbox"
                          onClick={() => handleClick(item.id)}
                          checked={payments.includes(item.id)}
                        />
                      </td>
                      <td>{moment(item.expected_payment_date).format('DD/MM/YYYY')}</td>
                      <td
                        className={classNames({
                          'text-danger': Number(item.value) < 0,
                          'text-success': Number(item.value) >= 0,
                        })}
                      >
                        {moneyTransform(item.value)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>

              <div className="hstack gap-2 justify-content-center mb-0">
                <button
                  type="button"
                  className={`btn btn-${actionButtonColor}`}
                  onClick={() => onActionClick(payments)}
                >
                  {actionButtonText}
                </button>
                <button type="button" className="btn btn-secondary" onClick={onCloseClick}>
                  Cancelar
                </button>
              </div>
            </>
          )}
        </ModalBody>
      </div>
    </Modal>
  );
};

export default ActionModal;
