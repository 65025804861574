import React, { useState } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import ProfileMenu from '../../CommonForBoth/TopBarDropDown/ProfileMenu';

import { withTranslation } from 'react-i18next';
import { ListGroup, ListGroupItem } from 'reactstrap';
import { searchItems } from './constants';

const Header = (props: any) => {
  const [search, setSearch] = useState('');
  const items = searchItems.filter((item: any) =>
    item.label.toLowerCase().includes(search.toLowerCase()),
  );

  function tToggle() {
    const body = document.body;
    if (window.screen.width <= 998) {
      body.classList.toggle('sidebar-enable');
    } else {
      body.classList.toggle('vertical-collpsed');
      body.classList.toggle('sidebar-enable');
    }
  }

  return (
    <>
      <header
        id="page-topbar"
        className={classNames({
          'layout-hml': process.env.REACT_APP_ENV !== 'production',
        })}
      >
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box d-lg-none d-md-block">
              <Link to="/" className="logo logo-dark">
                <span className="logo-sm">
                  <h1>SGI</h1>
                  <h2>Sistema de Gestão Interno</h2>
                </span>
              </Link>

              <Link to="/" className="logo logo-light">
                <span className="logo-sm">
                  <h1>SGI</h1>
                  <h2>Sistema de Gestão Interno</h2>
                </span>
              </Link>
            </div>

            <button
              type="button"
              onClick={() => tToggle()}
              className="btn btn-sm px-3 font-size-16 header-item "
              id="vertical-menu-btn"
            >
              <i className="fa fa-fw fa-bars" />
            </button>
            <form className="app-search d-none d-lg-block">
              <div className="position-relative">
                <input
                  type="text"
                  className="form-control"
                  onChange={(e: any) => setSearch(e.target.value)}
                  placeholder="Pesquisar..."
                />
                <span className="bx bx-search-alt" />

                {search && search.length > 2 && (
                  <div style={{ top: 50, position: 'absolute' }}>
                    <ListGroup>
                      {items.map((item: any) => (
                        <ListGroupItem tag="a" href={item.url} key={item.url}>
                          {item.label}
                        </ListGroupItem>
                      ))}
                    </ListGroup>
                  </div>
                )}
              </div>
            </form>
          </div>
          <div className="d-flex">
            <ProfileMenu />

            <div className="dropdown d-inline-block">
              <button
                type="button"
                className="btn header-item noti-icon right-bar-toggle"
                onClick={props.toggleCanvas}
              >
                <i className="bx bx-cog bx-spin" />
              </button>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default withTranslation()(Header);
