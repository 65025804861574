import React from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { Link } from 'react-router-dom';
import { Badge } from 'reactstrap';
import CopyRow from '@component/common/TableContainer/CopyRow';

export const buildColumns = (isAdmin: boolean, clientID: string) => {
  const columnHelper = createColumnHelper();

  return [
    columnHelper.accessor('identification_number', {
      header: 'ID do contrato',
      size: 5,
      cell: ({ row, getValue }: any) => (
        <>
          <h5 className="font-size-14 mb-1">
            <Link to={`/contracts/${row.original.id}`} className="text-dark">
              <Badge color="primary" className="font-size-12" pill>
                # {getValue()}
              </Badge>
            </Link>
          </h5>
        </>
      ),
      meta: {
        disableFilterType: true,
      },
    }),
    columnHelper.accessor('client.name', {
      header: 'Cliente',
      size: 15,
      cell: ({ row, getValue }: any) => (
        <>
          <h5 className="font-size-14 mb-1">
            <Link to={`/contracts/${row.original.id}`} className="text-dark">
              {getValue()}
            </Link>
          </h5>
        </>
      ),
      meta: {
        isJoinColumn: true,
      },
    }),
    columnHelper.accessor('profitability_group', {
      header: 'Grupo de rentabilidade',
      size: 13,
      cell: ({ getValue }: any) => (
        <p className="font-size-14 mb-1">
          {getValue().description} - {getValue().percentage.replace('.', ',')}%
        </p>
      ),
      meta: {
        disableFilterType: true,
      },
    }),
    columnHelper.accessor('description', {
      cell: ({ getValue, row }: any) => <CopyRow id={row.original.id} value={getValue()} />,
      header: 'Descrição',
      size: 20,
    }),
    columnHelper.accessor('recurring_withdrawal', {
      cell: ({ getValue }: any) => (
        <>
          {getValue() ? (
            <Badge color="success" className="font-size-12" pill>
              Sim
            </Badge>
          ) : (
            <Badge color="danger" className="font-size-12" pill>
              Não
            </Badge>
          )}
        </>
      ),
      header: 'Retirada recorrente',
      size: 5,
      meta: {
        filterType: 'select',
        filterOptions: [
          { value: '', label: 'Selecione...' },
          { value: 'true', label: 'Sim' },
          { value: 'false', label: 'Não' },
        ],
      },
    }),
    columnHelper.accessor('active', {
      cell: ({ getValue }: any) => (
        <>
          {getValue() ? (
            <Badge color="success" className="font-size-12" pill>
              Ativado
            </Badge>
          ) : (
            <Badge color="danger" className="font-size-12" pill>
              Desativado
            </Badge>
          )}
        </>
      ),
      header: 'Ativo',
      size: 5,
      meta: {
        filterType: 'select',
        filterOptions: [
          { value: '', label: 'Selecione...' },
          { value: 'true', label: 'Sim' },
          { value: 'false', label: 'Não' },
        ],
      },
    }),
    columnHelper.accessor('partner.name', {
      cell: ({ row, getValue }: any) => (
        <>
          {getValue() ? (
            <h5 className="font-size-14 mb-1">
              <Link
                to={`/contracts/${row.original.id}/link-partner/${row.original.partner_id}`}
                className="text-dark mb-0"
              >
                {getValue()}
              </Link>
            </h5>
          ) : (
            <div className="d-flex gap-3">
              <Link
                to={`/contracts/${row.original.id}/link-partner`}
                className="btn btn-primary btn-sm btn-rounded"
              >
                <i className="mdi mdi-plus-circle-outline me-1"></i> Vincular acessor
              </Link>
            </div>
          )}
        </>
      ),
      header: 'Acessor',
      size: 15,
      meta: {
        isJoinColumn: true,
      },
    }),
    columnHelper.accessor('actions', {
      header: 'Relatórios',
      size: 15,
      meta: {
        disableFilter: true,
      },
      cell: ({ row }: any) => {
        if (!isAdmin) {
          return;
        }
        return (
          <div className="d-flex gap-3">
            <Link
              to={`/contracts/${row.original.id}/contracts-transactions?client_id=${clientID}`}
              className="btn btn-primary btn-sm"
            >
              <i className="mdi mdi-eye font-size-14" id="edittooltip" /> Transações
            </Link>

            <Link
              to={`/contracts/${row.original.id}/contracts-extracts?client_id=${clientID}`}
              className="btn btn-primary btn-sm"
            >
              <i className="mdi mdi-eye font-size-14" id="edittooltip" /> Extrato
            </Link>
          </div>
        );
      },
    }),
  ];
};
