import React from 'react';
import { Alert, Label, Form, FormFeedback, FormGroup, Col, Row, Input } from 'reactstrap';
import AsyncSelect from 'react-select/async';

import instance from '@helpers/request/instance';
import { useSelector } from 'react-redux';

const ContractForm = ({
  registrationError,
  type = 'create',
  showAlert,
  validation,
  loading,
}: any) => {
  const { layoutModeTypes } = useSelector((state: any) => state.Layout);

  const handleClientChange = (data: { value: string; label: string }) => {
    validation.setFieldValue('client', data);
  };

  const handleProfitabilityGroupChange = (data: { value: string; label: string }) => {
    validation.setFieldValue('profitabilityGroup', data);
  };

  const getProfitabilityGroups = (_: string, callback: (data: any) => void) => {
    new Promise((resolve, reject) => {
      instance
        .get('/profitability-group')
        .then((response) => {
          const data = response.data.items.map((profitabilityGroup: any) => ({
            value: profitabilityGroup.id,
            label: `${profitabilityGroup.description} - ${profitabilityGroup.percentage.replace(
              '.',
              ',',
            )}%`,
          }));

          callback(data);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  };

  const getClients = (_: string, callback: (data: any) => void) => {
    new Promise((resolve, reject) => {
      instance
        .get('/clients')
        .then((response) => {
          const data = response.data.items.map((client: any) => ({
            value: client.id,
            label: client.name,
          }));

          callback(data);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  };

  return (
    <Form
      className="form-horizontal"
      onSubmit={(e) => {
        e.preventDefault();
        validation.handleSubmit();
        return false;
      }}
    >
      {showAlert ? (
        <Alert color="success">{type === 'create' ? 'Cadastrado' : 'Editado'} com sucesso.</Alert>
      ) : null}

      {registrationError && registrationError ? (
        <Alert color="danger">{registrationError}</Alert>
      ) : null}

      <FormGroup className="mb-4" row>
        <Label className="col-form-label col-lg-2">Cliente</Label>
        <Col lg={10}>
          <AsyncSelect
            name="client"
            className="react-select-container"
            classNames={{
              singleValue: () => 'react-select-single-value',
              placeholder: () => 'react-select-placeholder',
            }}
            aria-placeholder="Selecione um cliente..."
            cacheOptions
            defaultOptions
            loadOptions={getClients}
            onChange={handleClientChange}
            value={validation.values.client}
            theme={(theme) => {
              if (layoutModeTypes !== 'dark') {
                return theme;
              }

              return {
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: '#272727',
                  primary: '#272727',
                  neutral0: '#1E1E1E',
                },
              };
            }}
          />
          {validation.touched.client_id && validation.errors.client_id ? (
            <FormFeedback type="invalid">{validation.errors.client_id}</FormFeedback>
          ) : null}
        </Col>
      </FormGroup>

      <FormGroup className="mb-4" row>
        <Label className="col-form-label col-lg-2">Grupo de rentabilidade</Label>
        <Col lg={10}>
          <AsyncSelect
            name="profitabilityGroup"
            className="react-select-container"
            classNames={{
              singleValue: () => 'react-select-single-value',
              placeholder: () => 'react-select-placeholder',
            }}
            placeholder="Selecione um grupo de rentabilidade..."
            cacheOptions
            defaultOptions
            loadOptions={getProfitabilityGroups}
            onChange={handleProfitabilityGroupChange}
            value={validation.values.profitabilityGroup}
            theme={(theme) => {
              if (layoutModeTypes !== 'dark') {
                return theme;
              }

              return {
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: '#272727',
                  primary: '#272727',
                  neutral0: '#1E1E1E',
                },
              };
            }}
          />
          {validation.touched.percentage && validation.errors.percentage ? (
            <FormFeedback type="invalid">{validation.errors.percentage}</FormFeedback>
          ) : null}
        </Col>
      </FormGroup>

      <FormGroup className="mb-4" row>
        <Label className="col-form-label col-lg-2">Retirada recorrente</Label>
        <Col lg={10}>
          <Input
            id="recurring_withdrawal"
            name="recurring_withdrawal"
            className="form-control"
            type="select"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={String(validation.values.recurring_withdrawal) || ''}
            invalid={
              !!(validation.touched.recurring_withdrawal && validation.errors.recurring_withdrawal)
            }
          >
            <option value="">Selecione uma opção</option>
            <option value="true">Sim</option>
            <option value="false">Não</option>
          </Input>
          {validation.touched.recurring_withdrawal && validation.errors.recurring_withdrawal ? (
            <FormFeedback type="invalid">{validation.errors.recurring_withdrawal}</FormFeedback>
          ) : null}
        </Col>
      </FormGroup>

      <FormGroup className="mb-4" row>
        <Label className="col-form-label col-lg-2">Ativo</Label>
        <Col lg={10}>
          <Input
            id="active"
            name="active"
            className="form-control"
            type="select"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={String(validation.values.active) || ''}
            invalid={!!(validation.touched.active && validation.errors.active)}
          >
            <option value="">Selecione uma opção</option>
            <option value="true">Ativado</option>
            <option value="false">Desativado</option>
          </Input>
          {validation.touched.active && validation.errors.active ? (
            <FormFeedback type="invalid">{validation.errors.active}</FormFeedback>
          ) : null}
        </Col>
      </FormGroup>

      <FormGroup className="mb-4" row>
        <Label className="col-form-label col-lg-2">Descrição</Label>
        <Col lg={10}>
          <Input
            name="description"
            type="textarea"
            placeholder="Digite uma descrição"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.description || ''}
            invalid={!!(validation.touched.description && validation.errors.description)}
          />
          {validation.touched.description && validation.errors.description ? (
            <FormFeedback type="invalid">{validation.errors.description}</FormFeedback>
          ) : null}
        </Col>
      </FormGroup>

      <Row className="justify-content-end">
        <Col lg={10}>
          <button className="btn btn-primary btn-block" disabled={loading} type="submit">
            {loading ? <>Salvando...</> : `${type === 'create' ? 'Cadastrar' : 'Editar'} contrato`}
          </button>
        </Col>
      </Row>
    </Form>
  );
};

export default ContractForm;
