import { CustodyDetails } from '../types';

export const initialData: CustodyDetails = {
  items: [
    {
      contract: {
        id: 0,
        type: '',
        profitability: 0,
      },
      client: {
        code: '',
        name: '',
      },
      current_custody: '',
      projection: {
        monthly: '',
        bimonthly: '',
        quarterly: '',
        biannual: '',
        annual: '',
      },
    },
  ],
  total: {
    monthly: {
      total: '',
      relocation: '',
      withdrawal: '',
    },
    bimonthly: {
      total: '',
      relocation: '',
      withdrawal: '',
    },
    quarterlymonthly: {
      total: '',
      relocation: '',
      withdrawal: '',
    },
    biannual: {
      total: '',
      relocation: '',
      withdrawal: '',
    },
    annual: {
      total: '',
      relocation: '',
      withdrawal: '',
    },
    custody: {
      total: '',
      relocation: '',
      withdrawal: '',
    },
  },
  totalItems: 0,
};
