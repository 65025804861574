import { InstanceError } from '@apptypes/instance';
import { IPagination } from '@apptypes/pagination';
import { generateToQueryString } from '@helpers/queryString';
import { ERROR_STATUS_MESSAGE } from '@helpers/request/constants';
import instance from '@helpers/request/instance';
import { IClientCreate } from '@modules/Client/types';
import { IContractCreateForm } from '@modules/Contract/types';
import { IPartnerCreate } from '@modules/Partners/types';
import { ColumnFiltersState } from '@tanstack/react-table';
import { getCookie } from '@helpers/cookies';
import { AUTH_COOKIE_NAME } from '@helpers/constants';

export const getAll = async (
  page = 1,
  size = 10,
  filterItems?: ColumnFiltersState,
): Promise<IPagination<IPartnerCreate>> => {
  try {
    const queryString = generateToQueryString({ page, size, filterItems });
    const { data } = await instance.get(`/partners${queryString}`);
    return data;
  } catch (error) {
    const errorMessage: Record<number, string> = {
      [ERROR_STATUS_MESSAGE.NOT_FOUND]: 'Acessores não encontrados',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
    };

    throw new Error(
      errorMessage[(error as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};

export const getClientsByPartnerID = async (partnerID: string): Promise<IClientCreate[]> => {
  try {
    const { data } = await instance.get(`/partners/${partnerID}/clients`);
    return data;
  } catch (error) {
    const errorMessage: Record<number, string> = {
      [ERROR_STATUS_MESSAGE.NOT_FOUND]: 'Clientes não encontrados',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
    };

    throw new Error(
      errorMessage[(error as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};

export const getContractsByPartnerID = async (
  partnerID: string,
): Promise<IContractCreateForm[]> => {
  try {
    const { data } = await instance.get(`/partners/${partnerID}/contracts`);
    return data;
  } catch (error) {
    const errorMessage: Record<number, string> = {
      [ERROR_STATUS_MESSAGE.NOT_FOUND]: 'Contratos não encontrados',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
    };

    throw new Error(
      errorMessage[(error as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};

export const getByID = async (partnerID: string): Promise<IPartnerCreate> => {
  try {
    const { data } = await instance.get(`/partners/${partnerID}`);
    return data;
  } catch (error) {
    const errorMessage: Record<number, string> = {
      [ERROR_STATUS_MESSAGE.NOT_FOUND]: 'Acessor não encontrado',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
    };

    throw new Error(
      errorMessage[(error as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};

export const create = async (payload: IPartnerCreate): Promise<IPartnerCreate> => {
  try {
    const { data } = await instance.post('/partners', payload);
    return data;
  } catch (error) {
    const errorMessage: Record<number, string> = {
      [ERROR_STATUS_MESSAGE.BAD_REQUEST]: 'Não foi possível criar o acessor',
      [ERROR_STATUS_MESSAGE.NOT_FOUND]: 'Acessor não encontrado',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
    };

    throw new Error(
      errorMessage[(error as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};

export const update = async (
  partnerID: string,
  payload: IPartnerCreate,
): Promise<IPartnerCreate> => {
  try {
    const { data } = await instance.put(`/partners/${partnerID}`, payload);
    return data;
  } catch (error) {
    const errorMessage: Record<number, string> = {
      [ERROR_STATUS_MESSAGE.BAD_REQUEST]: 'Não foi possível editar o acessor',
      [ERROR_STATUS_MESSAGE.NOT_FOUND]: 'Acessor não encontrado',
      [ERROR_STATUS_MESSAGE.UNAUTHORIZED]: 'Você não tem permissão para editar o acessor',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
    };

    throw new Error(
      errorMessage[(error as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};

export const deleteByID = async (partnerID: string): Promise<void> => {
  try {
    await instance.delete(`/partners/${partnerID}`);
  } catch (error) {
    const errorMessage: Record<number, string> = {
      [ERROR_STATUS_MESSAGE.BAD_REQUEST]: 'Não foi possível deletar o acessor',
      [ERROR_STATUS_MESSAGE.UNAUTHORIZED]: 'Você não tem permissão para deletar o acessor',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
    };

    throw new Error(
      errorMessage[(error as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};

export const getAllStatements = async ({
  partnerID,
  initialDate,
  finalDate,
}: {
  partnerID: string;
  initialDate: string;
  finalDate: string;
}) => {
  try {
    const { data } = await instance.get(
      `/reports/partner-statements/${partnerID}?initial_date=${initialDate}&final_date=${finalDate}`,
    );

    return data;
  } catch (error) {
    const errorMessage: Record<number, string> = {
      [ERROR_STATUS_MESSAGE.BAD_REQUEST]: 'Não foi possível buscar o extrado do acessor',
      [ERROR_STATUS_MESSAGE.UNAUTHORIZED]: 'Você não tem permissão para buscar o extrato acessor',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
    };

    throw new Error(
      errorMessage[(error as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};

export const getEarnings = async ({
  partnerID,
  period = 'month',
  initialDate,
  finalDate,
}: {
  partnerID: string;
  period?: string;
  initialDate: string;
  finalDate: string;
}) => {
  try {
    const { data } = await instance.get(
      `/reports/partner-statements/earning/${partnerID}?period=${period}&initial_date=${initialDate}&final_date=${finalDate}`,
    );
    return data;
  } catch (error) {
    const errorMessage: Record<number, string> = {
      [ERROR_STATUS_MESSAGE.NOT_FOUND]: 'Ganhos não encontrados',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
    };

    throw new Error(
      errorMessage[(error as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};

export const exportData = async (page = 1, size = 1000, filterItems?: ColumnFiltersState) => {
  try {
    const queryString = generateToQueryString({ page, size, filterItems });
    const file = await fetch(`${process.env.BASE_URL}/partners/report/export${queryString}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: getCookie(AUTH_COOKIE_NAME),
      } as any,
    });

    const response = await file.arrayBuffer();
    const fileBlog = new Blob([response], { type: 'text/csv' });

    return URL.createObjectURL(fileBlog);
  } catch (error) {
    const errorMessage: Record<number, string> = {
      [ERROR_STATUS_MESSAGE.BAD_REQUEST]: 'Não foi possível buscar o arquivo',
      [ERROR_STATUS_MESSAGE.NOT_FOUND]: 'arquivo não encontrado',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
    };

    throw new Error(
      errorMessage[(error as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};
