export enum Periodicity {
  Daily = 'D',
  Weekly = 'S',
  Monthly = 'M',
  Annual = 'A',
}

export enum Repetition {
  Unique = 'U',
  Recurring = 'P',
  Fixed = 'F',
}

export enum TransactionTypeEnum {
  Income = 'R',
  Expense = 'D',
  Transfer = 'T',
}
