import { moneyTransform } from '@helpers/transforms/money';
import { DailyEarnings } from '../types';
import moment from 'moment';

interface IOptions {
  items: DailyEarnings[];
}

export const options = ({ items }: IOptions) => {
  const dates = items.map((item: DailyEarnings) => moment(item.referenceDate).format('DD/MM/YYYY'));

  const dayTradeAmount = {
    name: 'DayTrade',
    type: 'line',
    stack: 'Total',
    data: items.map((item: DailyEarnings) => item.details.dayTradeAmount),
  };

  const swingTradeAmount = {
    name: 'SwingTrade',
    type: 'line',
    stack: 'Total',
    data: items.map((item: DailyEarnings) => item.details.swingTradeAmount),
  };

  return {
    title: {
      text: 'Ganhos diários',
    },
    tooltip: {
      trigger: 'axis',
      formatter: (params: any) => {
        const items = params.map(
          (item: any) =>
            `<div>
              <strong style="color: ${item.color};">${
                item.seriesName
              }</strong>: <span style="color: ${
                item.value >= 0 ? 'green' : 'red'
              };">${moneyTransform(item.value)}</span>
            </div>`,
        );

        const total = params.reduce((acc: any, item: any) => acc + item.value, 0);

        items.push(`<div style="color: black;">
              <strong>Total</strong>: <span style="color: ${
                total >= 0 ? 'green' : 'red'
              };">${moneyTransform(total)}</span>
            </div>`);

        return items.join('');
      },
    },
    legend: {
      data: ['DayTrade', 'SwingTrade', 'Total'],
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    toolbox: {
      feature: {
        saveAsImage: {},
      },
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: dates,
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        formatter: (value: number) => moneyTransform(value),
      },
    },
    series: [dayTradeAmount, swingTradeAmount],
  };
};
