import React from 'react';

import CategoriesProvider from './CategoriesContext';
import TransactionsProvider from './TransactionsContext';

export default function ApplicationProviders({ children }: { children: React.ReactNode }) {
  return (
    <CategoriesProvider>
      <TransactionsProvider>{children}</TransactionsProvider>
    </CategoriesProvider>
  );
}
