import React, { useMemo, useRef, useState } from 'react';
import Calendar from 'react-calendar';
import { useClickAway } from 'react-use';

import 'react-calendar/dist/Calendar.css';

import * as S from './styles';

import { CalenderPickerProps } from './types';
import { Value, ValuePiece } from '@modules/Finance/contexts/TransactionsContext/types';
import { useTransactions } from '@modules/Finance/contexts/TransactionsContext';

export const CalendarPicker = ({ onChange, setValue, value }: CalenderPickerProps) => {
  // * [States] * //
  const [isCalendarVisible, setIsCalendarVisible] = useState(false);
  const [selectedPeriod, setSelectedPeriod] = useState<string>('daily');

  // * [Refs] * //
  const calendarContainerRef = useRef(null);

  // * [Hooks] * //
  useClickAway(calendarContainerRef, () => setIsCalendarVisible(false));
  const { setStartEndDate } = useTransactions();

  // * [Functions] * //
  const formatDatePiece = (piece: ValuePiece) =>
    piece
      ? piece
          .toLocaleDateString('pt-BR', { day: '2-digit', month: 'short', year: 'numeric' })
          .replace('.', '')
          .replace(' de ', ' ')
      : '';

  const calculateDateRange = (startDate: Date, daysToAdd: number) => {
    const start = new Date(startDate);
    const end = new Date(start);
    end.setDate(start.getDate() + daysToAdd - 1);
    setStartEndDate({
      start,
      end,
    });
    return `${formatDatePiece(start)} a ${formatDatePiece(end)}`;
  };

  const formatSelectedDate = (date: Value, period: string): string => {
    if (date === null) {
      return '';
    }

    if (Array.isArray(date)) {
      const [start, end] = date;
      setStartEndDate({
        start: new Date(String(start)),
        end: new Date(String(end)),
      });
      return `${formatDatePiece(start)} a ${formatDatePiece(end)}`;
    }

    if (period === 'weekly') {
      const startOfWeek = new Date(date);
      startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay());
      return calculateDateRange(startOfWeek, 7);
    }

    if (period === 'fortnightly') {
      // Quinzenal
      return calculateDateRange(new Date(date), 15);
    }

    if (period === 'monthly') {
      // Mensal
      const startOfMonth = new Date(date);
      startOfMonth.setDate(1);
      const endOfMonth = new Date(startOfMonth);
      endOfMonth.setMonth(startOfMonth.getMonth() + 1);
      endOfMonth.setDate(0); // Último dia do mês
      setStartEndDate({
        start: new Date(startOfMonth),
        end: new Date(endOfMonth),
      });
      return `${formatDatePiece(startOfMonth)} a ${formatDatePiece(endOfMonth)}`;
    }

    if (period === 'bimonthly') {
      // Bimestral
      const startOfPeriod = new Date(date);
      startOfPeriod.setDate(1);
      const endOfPeriod = new Date(startOfPeriod);
      endOfPeriod.setMonth(startOfPeriod.getMonth() + 2);
      endOfPeriod.setDate(0); // Último dia do segundo mês
      setStartEndDate({
        start: startOfPeriod,
        end: endOfPeriod,
      });
      return `${formatDatePiece(startOfPeriod)} a ${formatDatePiece(endOfPeriod)}`;
    }

    if (period === 'quarterly') {
      // Trimestral
      const startOfPeriod = new Date(date);
      startOfPeriod.setDate(1);
      const endOfPeriod = new Date(startOfPeriod);
      endOfPeriod.setMonth(startOfPeriod.getMonth() + 3);
      endOfPeriod.setDate(0); // Último dia do terceiro mês
      setStartEndDate({
        start: startOfPeriod,
        end: endOfPeriod,
      });
      return `${formatDatePiece(startOfPeriod)} a ${formatDatePiece(endOfPeriod)}`;
    }

    if (period === 'semiannual') {
      // Semestral
      const startOfPeriod = new Date(date);
      startOfPeriod.setDate(1);
      const endOfPeriod = new Date(startOfPeriod);
      endOfPeriod.setMonth(startOfPeriod.getMonth() + 6);
      endOfPeriod.setDate(0); // Último dia do sexto mês
      setStartEndDate({
        start: startOfPeriod,
        end: endOfPeriod,
      });
      return `${formatDatePiece(startOfPeriod)} a ${formatDatePiece(endOfPeriod)}`;
    }

    if (period === 'yearly') {
      // Anual
      const startOfYear = new Date(date);
      startOfYear.setMonth(0, 1); // Primeiro dia do ano
      const endOfYear = new Date(startOfYear);
      endOfYear.setMonth(11, 31); // Último dia do ano
      setStartEndDate({
        start: startOfYear,
        end: endOfYear,
      });
      return `${formatDatePiece(startOfYear)} a ${formatDatePiece(endOfYear)}`;
    }

    setStartEndDate({
      start: date,
      end: date,
    });
    return formatDatePiece(date);
  };

  const handleDateSelection = (date: Value) => {
    setValue(date);
    setIsCalendarVisible(false);
  };

  const isSelectedDateToday = (date: Value) => {
    if (!date || Array.isArray(date)) return false;
    const today = new Date();
    return (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    );
  };

  const handleSetCurrentDateToToday = () => {
    setValue(new Date());
    setIsCalendarVisible(false);
    setSelectedPeriod('daily');
  };

  const handleDateNavigation = (direction: 'prev' | 'next'): void => {
    if (!value) return;

    const increment = direction === 'next' ? 1 : -1;

    setValue((previousDate) => {
      if (!previousDate || Array.isArray(previousDate)) return previousDate;

      const updatedDate = new Date(previousDate);

      const periodIncrements: Record<string, number> = {
        daily: increment,
        weekly: increment * 7,
        fortnightly: increment * 15,
        monthly: increment * 30,
        bimonthly: increment * 60,
        quarterly: increment * 90,
        semiannual: increment * 180,
        yearly: increment * 365,
      };

      const daysToAdd = periodIncrements[selectedPeriod];
      updatedDate.setDate(updatedDate.getDate() + daysToAdd);

      return updatedDate;
    });
  };

  const handlePeriodChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedPeriod(e.target.value);
    setValue(value);
  };

  // * [Objects] * //
  const PERIOD_OPTIONS = [
    { label: 'Diário', value: 'daily' },
    { label: 'Semanal', value: 'weekly' },
    { label: 'Quinzenal', value: 'fortnightly' },
    { label: 'Mensal', value: 'monthly' },
    { label: 'Bimestral', value: 'bimonthly' },
    { label: 'Trimestral', value: 'quarterly' },
    { label: 'Semestral', value: 'semiannual' },
    { label: 'Anual', value: 'yearly' },
    { label: 'Personalizado', value: 'custom' },
  ];

  // * [Variables] * //
  const currentDateSelected = useMemo(
    () => formatSelectedDate(value, selectedPeriod),
    [value, selectedPeriod],
  );

  return (
    <S.CalendarWrapper ref={calendarContainerRef}>
      <S.DateNavigationContainer>
        <S.NavigationButton onClick={() => handleDateNavigation('prev')}>
          <S.NavigationIcon className="fas fa-chevron-left" />
        </S.NavigationButton>

        <S.DateDisplayButton onClick={() => setIsCalendarVisible((prev) => !prev)}>
          {currentDateSelected}
        </S.DateDisplayButton>

        {isCalendarVisible && (
          <S.CalendarDropdown>
            <Calendar
              onChange={handleDateSelection}
              value={value}
              selectRange={selectedPeriod === 'custom'}
              locale="pt-BR"
            />
          </S.CalendarDropdown>
        )}

        <S.NavigationButton onClick={() => handleDateNavigation('next')}>
          <S.NavigationIcon className="fas fa-chevron-right" />
        </S.NavigationButton>
      </S.DateNavigationContainer>

      <S.QuickActionsContainer>
        <S.TodayButton isActive={isSelectedDateToday(value)} onClick={handleSetCurrentDateToToday}>
          Hoje
        </S.TodayButton>

        <S.PeriodSelector
          type="select"
          value={selectedPeriod}
          onChange={(e) => handlePeriodChange(e)}
        >
          {PERIOD_OPTIONS.map((option) => (
            <S.PeriodOption key={option.value} value={option.value}>
              {option.label}
            </S.PeriodOption>
          ))}
        </S.PeriodSelector>
      </S.QuickActionsContainer>
    </S.CalendarWrapper>
  );
};
