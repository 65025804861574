import { InstanceError } from '@apptypes/instance';
import { UNEXPECTED_ERROR } from '@helpers/constants';
import { ERROR_STATUS_MESSAGE } from '@helpers/request/constants';
import instance from '@helpers/request/financialMarket';
import { IDailyOperation, IDailyOperationClose } from '../types';
import { IFinancePagination } from '@apptypes/pagination';

export const getByID = async (dailyOperationId: string): Promise<IDailyOperation> => {
  try {
    const { data } = await instance.get(`/daily-operations/${dailyOperationId}`);
    return data;
  } catch (error) {
    const errorMessage: Record<number, string> = {
      [ERROR_STATUS_MESSAGE.NOT_FOUND]: 'Operação diária não encontrado',
      [ERROR_STATUS_MESSAGE.UNAUTHORIZED]:
        'Você não tem permissão para visualizar a operação diária',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
    };

    throw new Error(
      errorMessage[(error as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};

export const getAll = async ({
  page,
  limit,
  filters = '',
}: {
  page: number;
  limit: number;
  filters: string;
}): Promise<IFinancePagination<IDailyOperation>> => {
  try {
    const { data } = await instance.get(
      `/daily-operations?page=${page}&limit=${limit}${filters ? `&filters=${filters}` : ''}`,
    );
    return data;
  } catch (error) {
    const errorMessage: Record<number, string> = {
      [ERROR_STATUS_MESSAGE.NOT_FOUND]: 'Operações diárias não encontradas',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
    };

    throw new Error(
      errorMessage[(error as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};

export const close = async (payload: IDailyOperationClose): Promise<IDailyOperation> => {
  try {
    const { data } = await instance.post('/daily-operations', {
      walletId: payload.wallet.value,
      finalDayTradeAmount: Number(payload.finalDayTradeAmount),
      finalSwingTradeAmount: Number(payload.finalSwingTradeAmount),
      referenceDate: `${payload.referenceDate}Z`,
    });
    return data;
  } catch (error) {
    const errorMessage: Record<number, string> = {
      [ERROR_STATUS_MESSAGE.ENTITY_NOT_FOUND_ERROR]: 'Não foi encontrada uma operação diária!',
      [ERROR_STATUS_MESSAGE.NOT_FOUND]: 'Operação diária nao encontrada',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: UNEXPECTED_ERROR,
    };

    throw new Error(
      errorMessage[(error as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};
