import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Button, Card, CardBody, CardText, CardTitle, Col, Row } from 'reactstrap';

import withRouter from '@common/withRouter';
import { Page } from '@component/common/Page';
import TableContainer from '@component/common/TableContainer';
import { CustodyDetails } from '@modules/Reports/types';
import { exportData, getCustodyDetaleidReport } from '@modules/Reports/services';
import { buildColumns } from '@modules/Reports/components/Table/contants';
import { moneyTransform } from '@helpers/transforms/money';
import { ColumnFiltersState } from '@tanstack/react-table';
import { IWithRouter } from '@apptypes/WithRouter';
import { SelectedSummary } from '../components/SelectedSummary';

import { initialData } from '@modules/Reports/constants/custodyDetailed';

const handleExportData = async () => {
  const fileUrl = await exportData();

  const link = document.createElement('a');
  link.href = fileUrl;
  link.download = `custody-detailed-export.csv`;
  link.click();
};

const CustodyDetailed = ({ setSearchParams, page, size }: IWithRouter) => {
  const [filters, setFilters] = useState<ColumnFiltersState>([]);
  const { data, isFetching, refetch } = useQuery<CustodyDetails>({
    queryKey: ['custodyReportDetailed', filters, page, size],
    queryFn: () => getCustodyDetaleidReport({ filters, page, size }),
    initialData,
    retry: 1,
  });

  const [selectedLines, setSelectedLines] = useState<any[]>([]);

  const handleSelectedLines = (data: any) => {
    const index = selectedLines.findIndex((line) => line.contract.id === data.contract.id);
    if (index >= 0) {
      setSelectedLines(selectedLines.filter((_, i) => i !== index));
    } else {
      setSelectedLines([...selectedLines, data]);
    }
  };

  return (
    <Page
      title="Relatórios"
      breadcrumb={{
        title: 'Relatório de custódia detalhado',
        titleHref: '/reports',
        breadcrumbItem: 'Relatório de custódia',
      }}
    >
      <Row>
        <Col sm={12}>
          <h1 className="font-size-16 mb-4">Custódia total</h1>
        </Col>
        <Col>
          <Card>
            <CardBody>
              <CardTitle>Total</CardTitle>
              <CardText className="text-muted mb-0">
                Total: {moneyTransform(Number(data.total.custody.total))}
              </CardText>
              <CardText className="text-success mb-0">
                Realocado: {moneyTransform(Number(data.total.custody.relocation))}
              </CardText>
              <CardText className="text-danger mb-0">
                Saída: {moneyTransform(Number(data.total.custody.withdrawal))}
              </CardText>
            </CardBody>
          </Card>
        </Col>
        <Col>
          <Card>
            <CardBody>
              <CardTitle>Mensal</CardTitle>
              <CardText className="text-muted mb-0">
                Total: {moneyTransform(Number(data.total.monthly.total))}
              </CardText>
              <CardText className="text-success mb-0">
                Realocado: {moneyTransform(Number(data.total.monthly.relocation))}
              </CardText>
              <CardText className="text-danger mb-0">
                Saída: {moneyTransform(Number(data.total.monthly.withdrawal))}
              </CardText>
            </CardBody>
          </Card>
        </Col>
        <Col>
          <Card>
            <CardBody>
              <CardTitle>Bimestal</CardTitle>
              <CardText className="text-muted mb-0">
                Total: {moneyTransform(Number(data.total.bimonthly.total))}
              </CardText>
              <CardText className="text-success mb-0">
                Realocado: {moneyTransform(Number(data.total.bimonthly.relocation))}
              </CardText>
              <CardText className="text-danger mb-0">
                Saída: {moneyTransform(Number(data.total.bimonthly.withdrawal))}
              </CardText>
            </CardBody>
          </Card>
        </Col>
        <Col>
          <Card>
            <CardBody>
              <CardTitle>Trimestral</CardTitle>
              <CardText className="text-muted mb-0">
                Total: {moneyTransform(Number(data.total.quarterlymonthly.total))}
              </CardText>
              <CardText className="text-success mb-0">
                Realocado: {moneyTransform(Number(data.total.quarterlymonthly.relocation))}
              </CardText>
              <CardText className="text-danger mb-0">
                Saída: {moneyTransform(Number(data.total.quarterlymonthly.withdrawal))}
              </CardText>
            </CardBody>
          </Card>
        </Col>
        <Col>
          <Card>
            <CardBody>
              <CardTitle>Semestral</CardTitle>
              <CardText className="text-muted mb-0">
                Total: {moneyTransform(Number(data.total.biannual.total))}
              </CardText>
              <CardText className="text-success mb-0">
                Realocado: {moneyTransform(Number(data.total.biannual.relocation))}
              </CardText>
              <CardText className="text-danger mb-0">
                Saída: {moneyTransform(Number(data.total.biannual.withdrawal))}
              </CardText>
            </CardBody>
          </Card>
        </Col>
        <Col>
          <Card>
            <CardBody>
              <CardTitle>Anual</CardTitle>
              <CardText className="text-muted mb-0">
                Total: {moneyTransform(Number(data.total.annual.total))}
              </CardText>
              <CardText className="text-success mb-0">
                Realocado: {moneyTransform(Number(data.total.annual.relocation))}
              </CardText>
              <CardText className="text-danger mb-0">
                Saída: {moneyTransform(Number(data.total.annual.withdrawal))}
              </CardText>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col lg="12">
          <Card>
            <CardBody>
              <TableContainer
                moduleName="custody-details"
                columns={buildColumns(selectedLines, handleSelectedLines)}
                customElement={
                  <>
                    <Button type="button" color="primary" outline onClick={handleExportData}>
                      Exportar dados
                    </Button>
                  </>
                }
                currentPage={page}
                customPageSize={size}
                data={data.items}
                loading={isFetching}
                totalItems={data.totalItems || 0}
                handleFilters={setFilters}
                setSearchParams={setSearchParams}
                refetch={refetch}
                isAddRegister
                isPagination
                isShowingPageLength
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
      <SelectedSummary selectedLines={selectedLines} />
    </Page>
  );
};

export default withRouter(CustodyDetailed);
