import React, { useState } from 'react';

import { IExpense, IIncome } from '@modules/Finance/types/transactions';
import { Repetition, TransactionTypeEnum } from '@modules/Finance/ultis/transactions/enums';

import * as S from './styles';

import { ITransactionListProps } from './types';

const TITLE_TRANSACTION = {
  [TransactionTypeEnum.Expense]: 'despesa',
  [TransactionTypeEnum.Income]: 'receita',
  [TransactionTypeEnum.Transfer]: 'transferência',
};

export const TransactionList = ({ transactions, action }: ITransactionListProps) => {
  // * [States] * //
  const [showDescription, setShowDescription] = useState<string[]>([]);
  const [showRepetition, setShowRepetition] = useState<string[]>([]);
  const [showCategory, setShowCategory] = useState<string[]>([]);
  const [showWarning, setShowWarning] = useState<string[]>([]);

  // * [Functions] * //
  const formatDate = (dateString: string): { month: string; day: string } => {
    if ([null, 'null', '', 'undefined'].includes(dateString)) return { month: '', day: '' };
    const date = new Date(dateString);

    const month = date.toLocaleString('pt-BR', { month: 'short' });
    const day = date.toLocaleString('pt-BR', { day: '2-digit' });

    return { month, day };
  };

  const groupTransactionsByDate = <T extends IExpense | IIncome>(transactions: T[]) => {
    return transactions?.reduce<Record<string, IExpense[] | IIncome[]>>((acc, transaction) => {
      const dateKey = transaction?.paymentDate;

      if (!acc[dateKey]) {
        acc[dateKey] = [];
      }
      acc[dateKey].push(transaction as any);
      return acc;
    }, {});
  };

  const formatToBRNumber = (value: number) => {
    return new Intl.NumberFormat('pt-BR', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value);
  };

  const handleDescriptionTooltip = (transactionId: string) => {
    setShowDescription((prev) =>
      prev.includes(transactionId)
        ? prev.filter((id) => id !== transactionId)
        : [...prev, transactionId],
    );
  };

  const handleRepetitionTooltip = (transactionId: string) => {
    setShowRepetition((prev) =>
      prev.includes(transactionId)
        ? prev.filter((id) => id !== transactionId)
        : [...prev, transactionId],
    );
  };

  const handleCategoryTooltip = (transactionId: string) => {
    setShowCategory((prev) =>
      prev.includes(transactionId)
        ? prev.filter((id) => id !== transactionId)
        : [...prev, transactionId],
    );
  };

  const handleWarningTooltip = (transactionId: string) => {
    setShowWarning((prev) =>
      prev.includes(transactionId)
        ? prev.filter((id) => id !== transactionId)
        : [...prev, transactionId],
    );
  };

  // * [Render] * //
  const groupedTransactions = groupTransactionsByDate(transactions || []);

  return (
    <S.TransactionListContainer>
      {transactions?.length === 0 && (
        <p className="text-muted font-size-14 text-center">
          Nenhuma {TITLE_TRANSACTION[action]} encontrada.
        </p>
      )}
      {Object.entries(groupedTransactions)?.map(([dateKey, transactionGroup]) => {
        const { month, day } = formatDate(dateKey);

        return (
          <React.Fragment key={dateKey}>
            <S.TransactionDateWrapper>
              <S.TransactionDay>{day}</S.TransactionDay>
              <S.TransactionMonth>{month}</S.TransactionMonth>
            </S.TransactionDateWrapper>

            {transactionGroup?.map((expense) => {
              return (
                <S.TransactionItemContainer key={expense?.id}>
                  <S.TransactionItem>
                    <S.DescriptionCollumn>
                      <S.CategoryIconWrapper>
                        <S.CategoryIcon
                          color={expense?.category?.color}
                          className={expense?.category?.icon}
                          id={`category-${expense?.id}`}
                        />
                        <S.DetailsTooltip
                          isOpen={showCategory.includes(expense?.id)}
                          target={`category-${expense?.id}`}
                          toggle={() => handleCategoryTooltip(expense?.id)}
                        >
                          <S.DetailsText>Categoria: {expense?.category?.name}</S.DetailsText>
                        </S.DetailsTooltip>

                        <S.BadgeWarning id={`warning-${expense?.id}`}>2</S.BadgeWarning>
                        <S.DetailsTooltip
                          isOpen={showWarning.includes(expense?.id)}
                          target={`warning-${expense?.id}`}
                          toggle={() => handleWarningTooltip(expense?.id)}
                        >
                          <S.DetailsText>2 dias de atraso</S.DetailsText>
                        </S.DetailsTooltip>
                      </S.CategoryIconWrapper>

                      <S.DescriptionContent>
                        <S.DescriptionWrapper>
                          <S.DescriptionText>{expense?.description}</S.DescriptionText>

                          {expense?.tags?.length > 0 && (
                            <S.TagList>
                              <S.DetailsText color={expense?.category?.color}>
                                {expense?.category?.name}
                              </S.DetailsText>

                              {expense?.tags?.map((tag) => (
                                <S.TagItem key={tag.id}>{tag.name}</S.TagItem>
                              ))}
                            </S.TagList>
                          )}
                        </S.DescriptionWrapper>

                        {expense?.repetition === Repetition.Recurring && (
                          <S.RepeatCount>
                            {expense?.installment}/{expense?.installments}
                          </S.RepeatCount>
                        )}
                      </S.DescriptionContent>
                    </S.DescriptionCollumn>

                    <S.DetailsCollumn>
                      <S.DetailsWrapper>
                        {expense?.observation && (
                          <>
                            <S.DetailsIcon
                              id={`observation-${expense?.id}`}
                              className="mdi mdi-message-text"
                            />

                            <S.DetailsTooltip
                              isOpen={showDescription.includes(expense?.id)}
                              target={`observation-${expense?.id}`}
                              toggle={() => handleDescriptionTooltip(expense?.id)}
                            >
                              <S.DetailsText>{expense?.observation}</S.DetailsText>
                            </S.DetailsTooltip>
                          </>
                        )}

                        {expense?.repetition === Repetition.Fixed && (
                          <>
                            <S.DetailsIcon
                              id={`repetition-${expense?.id}`}
                              className="mdi mdi-repeat"
                            />
                            <S.DetailsTooltip
                              isOpen={showRepetition.includes(expense?.id)}
                              target={`repetition-${expense?.id}`}
                              toggle={() => handleRepetitionTooltip(expense?.id)}
                            >
                              <S.DetailsText>Essa é uma transação fixa</S.DetailsText>
                            </S.DetailsTooltip>
                          </>
                        )}
                      </S.DetailsWrapper>
                    </S.DetailsCollumn>

                    <S.AccountName>
                      {expense?.wallet?.name}/{expense?.paymentMethod?.name}
                    </S.AccountName>

                    <S.TransactionValueCollumn>
                      <S.TransactionValue>-{formatToBRNumber(expense?.amount)}</S.TransactionValue>
                    </S.TransactionValueCollumn>
                  </S.TransactionItem>

                  <S.ActionButtonIcon onClick={() => {}} className={'mdi mdi-dots-vertical'} />
                </S.TransactionItemContainer>
              );
            })}
          </React.Fragment>
        );
      })}
    </S.TransactionListContainer>
  );
};
