import React from 'react';
import {
  Alert,
  Input,
  Label,
  Form,
  FormFeedback,
  FormGroup,
  Col,
  Row,
  InputGroup,
} from 'reactstrap';
import { IWalletFormProps } from '../../types';
import { NumericFormat } from 'react-number-format';

import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/themes/material_blue.css';
import { Portuguese } from 'flatpickr/dist/l10n/pt';
import moment from 'moment';
import classNames from 'classnames';

export const WalletForm = ({
  loading = false,
  registrationError,
  showAlert,
  type = 'create',
  validation,
}: IWalletFormProps) => {
  return (
    <Form
      className="form-horizontal"
      onSubmit={(e) => {
        e.preventDefault();
        validation.handleSubmit();
        return false;
      }}
    >
      {showAlert ? (
        <Alert color="success">{type === 'create' ? 'Cadastrado' : 'Editado'} com sucesso.</Alert>
      ) : null}

      {registrationError && registrationError ? (
        <Alert color="danger">{registrationError}</Alert>
      ) : null}

      <FormGroup className="mb-4" row>
        <Label className="col-form-label col-lg-2">Ativo</Label>
        <Col lg={10}>
          <Input
            id="active"
            name="active"
            className="form-control"
            type="select"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.active || ''}
            invalid={!!(validation.touched.active && validation.errors.active)}
          >
            <option value="">Selecione uma opção</option>
            <option value="true">Ativado</option>
            <option value="false">Desativado</option>
          </Input>
          {validation.touched.active && validation.errors.active ? (
            <FormFeedback type="invalid">{validation.errors.active}</FormFeedback>
          ) : null}
        </Col>
      </FormGroup>

      <FormGroup className="mb-4" row>
        <Label className="col-form-label col-lg-2">Saldo</Label>
        <Col lg={10}>
          <InputGroup>
            <NumericFormat
              id="balance"
              name="balance"
              className="form-control"
              decimalSeparator=","
              thousandSeparator="."
              placeholder="Digite um saldo"
              prefix="R$ "
              value={validation.values.balance ? validation.values.balance : ''}
              decimalScale={2}
              onValueChange={(data: any) => validation.setFieldValue('balance', data.value)}
            />
          </InputGroup>
          {validation.touched.balance && validation.errors.balance ? (
            <FormFeedback type="invalid">{validation.errors.balance}</FormFeedback>
          ) : null}
        </Col>
      </FormGroup>

      <FormGroup className="mb-4" row>
        <Label className="col-form-label col-lg-2">Dia de fechamento</Label>
        <Col lg={10}>
          <Input
            name="closeDay"
            type="number"
            placeholder="Digite o dia de fechamento"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.closeDay || ''}
            invalid={!!(validation.touched.closeDay && validation.errors.closeDay)}
          />
          {validation.touched.closeDay && validation.errors.closeDay ? (
            <FormFeedback type="invalid">{validation.errors.closeDay}</FormFeedback>
          ) : null}
        </Col>
      </FormGroup>

      <FormGroup className="mb-4" row>
        <Label className="col-form-label col-lg-2">Dia de vencimento</Label>
        <Col lg={10}>
          <Input
            name="dueDay"
            type="number"
            placeholder="Digite o dia de vencimento"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.dueDay || ''}
            invalid={!!(validation.touched.dueDay && validation.errors.dueDay)}
          />
          {validation.touched.dueDay && validation.errors.dueDay ? (
            <FormFeedback type="invalid">{validation.errors.dueDay}</FormFeedback>
          ) : null}
        </Col>
      </FormGroup>

      <FormGroup className="mb-4" row>
        <Label className="col-form-label col-lg-2">Primeira data de fechamento</Label>
        <Col lg={10}>
          <Flatpickr
            name="firstDueDate"
            className={classNames(`form-control d-block`, {
              'is-invalid': !!validation.errors.firstDueDate,
            })}
            placeholder="Selecione uma data"
            onChange={(date: any) => {
              validation.setFieldValue('firstDueDate', moment(date[0]).format('YYYY-MM-DD'));
            }}
            value={validation.values.firstDueDate || ''}
            options={{
              altInput: true,
              altFormat: 'F j, Y',
              dateFormat: 'Y-m-d',
              locale: Portuguese,
            }}
          />
          {validation.errors.firstDueDate ? (
            <FormFeedback type="invalid">{validation.errors.firstDueDate}</FormFeedback>
          ) : null}
        </Col>
      </FormGroup>

      <FormGroup className="mb-4" row>
        <Label className="col-form-label col-lg-2">Nome do títular</Label>
        <Col lg={10}>
          <Input
            name="holderName"
            type="text"
            placeholder="Digite o nome do títular"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.holderName || ''}
            invalid={!!(validation.touched.holderName && validation.errors.holderName)}
          />
          {validation.touched.holderName && validation.errors.holderName ? (
            <FormFeedback type="invalid">{validation.errors.holderName}</FormFeedback>
          ) : null}
        </Col>
      </FormGroup>

      <FormGroup className="mb-4" row>
        <Label className="col-form-label col-lg-2">Emissor do cartão</Label>
        <Col lg={10}>
          <Input
            name="issuer"
            type="text"
            placeholder="Digite o emissor do cartão"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.issuer || ''}
            invalid={!!(validation.touched.issuer && validation.errors.issuer)}
          />
          {validation.touched.issuer && validation.errors.issuer ? (
            <FormFeedback type="invalid">{validation.errors.issuer}</FormFeedback>
          ) : null}
        </Col>
      </FormGroup>

      <FormGroup className="mb-4" row>
        <Label className="col-form-label col-lg-2">Número</Label>
        <Col lg={10}>
          <Input
            name="number"
            type="number"
            placeholder="Digite o número"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.number || ''}
            invalid={!!(validation.touched.number && validation.errors.number)}
          />
          {validation.touched.number && validation.errors.number ? (
            <FormFeedback type="invalid">{validation.errors.number}</FormFeedback>
          ) : null}
        </Col>
      </FormGroup>

      <FormGroup className="mb-4" row>
        <Label className="col-form-label col-lg-2">Nome</Label>
        <Col lg={10}>
          <Input
            name="name"
            type="text"
            placeholder="Digite o nome"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.name || ''}
            invalid={!!(validation.touched.name && validation.errors.name)}
          />
          {validation.touched.name && validation.errors.name ? (
            <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
          ) : null}
        </Col>
      </FormGroup>

      <FormGroup className="mb-4" row>
        <Label className="col-form-label col-lg-2">Tipo de conta</Label>
        <Col lg={10}>
          <Input
            id="type"
            name="type"
            className="form-control"
            type="select"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.type || ''}
            invalid={!!(validation.touched.type && validation.errors.type)}
          >
            <option value="">Selecione um tipo de conta corrente</option>
            <option value="CC">Conta corrente</option>
            <option value="CP">Conta poupança</option>
          </Input>
          {validation.touched.type && validation.errors.type ? (
            <FormFeedback type="invalid">{validation.errors.type}</FormFeedback>
          ) : null}
        </Col>
      </FormGroup>

      <Row className="justify-content-end">
        <Col lg={10}>
          <button className="btn btn-primary btn-block" type="submit" disabled={loading}>
            {loading ? <>Salvando...</> : `${type === 'create' ? 'Cadastrar' : 'Editar'}`}
          </button>
        </Col>
      </Row>
    </Form>
  );
};
