import React from 'react';
import Select from 'react-select';
import { Alert, Input, Label, Form, FormFeedback, Row, Col, FormGroup } from 'reactstrap';

import { banks } from '@helpers/banks/list';
import { IForm } from '@apptypes/form';
import { useSelector } from 'react-redux';

const PartnerBankDataForm = ({
  type = 'create',
  showAlert,
  registrationError,
  validation,
  loading,
}: IForm) => {
  const { layoutModeTypes } = useSelector((state: any) => state.Layout);

  const handleBankChange = (data: any) => {
    const bank = banks.find((bank) => bank.number === data.value);

    if (bank) {
      validation.setValues({
        ...validation.values,
        bank_number: bank.number,
        institution: bank.name,
      });
    }
  };

  const currentBank = banks.find(
    (bank: any) => String(bank.number) === String(validation.values.bank_number),
  );
  let defaultValue: { value: string; label: string } | null = null;

  if (currentBank) {
    defaultValue = {
      value: currentBank.number,
      label: `${currentBank.number} - ${currentBank.name}`,
    };
  }

  const bankList = banks.map((bank) => ({
    value: bank.number,
    label: `${bank.number} - ${bank.name}`,
  }));

  return (
    <Form
      className="form-horizontal"
      onSubmit={(e) => {
        e.preventDefault();
        validation.handleSubmit();
        return false;
      }}
    >
      {showAlert ? (
        <Alert color="success">
          Dados bancários {type === 'create' ? 'cadastrado' : 'editado'} com sucesso.
        </Alert>
      ) : null}

      {registrationError && registrationError ? (
        <Alert color="danger">{registrationError}</Alert>
      ) : null}

      <FormGroup className="mb-4" row>
        <Label className="col-form-label col-lg-2">Tipo de documento</Label>
        <Col lg={10}>
          <Input
            id="type"
            name="type"
            className="form-control"
            disabled={type === 'edit'}
            type="select"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.type || ''}
            invalid={!!(validation.touched.type && validation.errors.type)}
          >
            <option value="">Selecione um tipo de conta corrente</option>
            <option value="CC">Conta corrente</option>
            <option value="CP">Conta poupança</option>
          </Input>
          {validation.touched.type && validation.errors.type ? (
            <FormFeedback type="invalid">{validation.errors.type}</FormFeedback>
          ) : null}
        </Col>
      </FormGroup>

      <FormGroup className="mb-4" row>
        <Label className="col-form-label col-lg-2">Agência</Label>
        <Col lg={10}>
          <Input
            name="agency"
            type="text"
            placeholder="Digite o número da agência"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.agency || ''}
            invalid={!!(validation.touched.agency && validation.errors.agency)}
          />
          {validation.touched.agency && validation.errors.agency ? (
            <FormFeedback type="invalid">{validation.errors.agency}</FormFeedback>
          ) : null}
        </Col>
      </FormGroup>

      <FormGroup className="mb-4" row>
        <Label className="col-form-label col-lg-2">Número da conta</Label>
        <Col lg={10}>
          <Input
            name="account"
            type="text"
            placeholder="Digite o número da conta"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.account || ''}
            invalid={!!(validation.touched.account && validation.errors.account)}
          />
          {validation.touched.account && validation.errors.account ? (
            <FormFeedback type="invalid">{validation.errors.account}</FormFeedback>
          ) : null}
        </Col>
      </FormGroup>

      <FormGroup className="mb-4" row>
        <Label className="col-form-label col-lg-2">Instituição financeira</Label>
        <Col lg={10}>
          <Select
            placeholder="Buscar por instituição financeira"
            className="react-select-container"
            classNames={{
              singleValue: () => 'react-select-single-value',
              placeholder: () => 'react-select-placeholder',
            }}
            onChange={handleBankChange}
            value={defaultValue}
            options={bankList}
            theme={(theme) => {
              if (layoutModeTypes !== 'dark') {
                return theme;
              }

              return {
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: '#272727',
                  primary: '#272727',
                  neutral0: '#1E1E1E',
                },
              };
            }}
          />
          {validation.touched.bank_number && validation.errors.bank_number ? (
            <FormFeedback type="invalid">{validation.errors.bank_number}</FormFeedback>
          ) : null}
        </Col>
      </FormGroup>

      <FormGroup className="mb-4" row>
        <Label className="col-form-label col-lg-2">PIX</Label>
        <Col lg={10}>
          <Input
            name="pix"
            type="text"
            placeholder="Digite o número do PIX"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.pix || ''}
            invalid={!!(validation.touched.pix && validation.errors.pix)}
          />
          {validation.touched.pix && validation.errors.pix ? (
            <FormFeedback type="invalid">{validation.errors.pix}</FormFeedback>
          ) : null}
        </Col>
      </FormGroup>

      <Row className="justify-content-end">
        <Col lg={10}>
          <button className="btn btn-primary btn-block" disabled={loading} type="submit">
            {loading ? (
              <>Salvando...</>
            ) : (
              `${type === 'create' ? 'Cadastrar' : 'Editar'} Dados bancários`
            )}
          </button>
        </Col>
      </Row>
    </Form>
  );
};

export default PartnerBankDataForm;
