import React from 'react';
import Wallet from '@modules/Wallets/pages';
import WalletCreate from '@modules/Wallets/pages/create';
import WalletUpdate from '@modules/Wallets/pages/update';

export const walletRoutes = [
  { path: '/wallets', component: <Wallet /> },
  { path: '/wallets/create', component: <WalletCreate /> },
  { path: '/wallets/:walletId', component: <WalletUpdate /> },
];
