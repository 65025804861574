import { ColumnFiltersState, SortingState } from '@tanstack/react-table';
import { useEffect, useState } from 'react';

const getOrderByValues = (sorting: SortingState) => {
  const value = sorting.reduce((acc, item) => {
    acc += `${item.id}:${item.desc ? 'desc' : 'asc'},`;
    return acc;
  }, '');

  return value.substring(0, value.length - 1);
};

export const useFilters = ({
  handleFilters,
}: {
  handleFilters?: (data: ColumnFiltersState) => void;
}) => {
  const [filterTypes, setFilterTypes] = useState<Record<string, any>>();
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [enableFilters, setEnableFilters] = useState(true);
  const [sorting, setSorting] = useState<SortingState>([]);

  useEffect(() => {
    const newColumnFilters = columnFilters
      .filter((item) => item.id !== 'orderBy')
      .map((item) => {
        return {
          ...item,
          filter: filterTypes ? filterTypes[item.id].type : 'eq',
        };
      });

    const sortFilter = [
      ...newColumnFilters,
      {
        id: 'orderBy',
        value: getOrderByValues(sorting),
      },
    ];

    if (handleFilters) {
      handleFilters(sorting.length > 0 ? sortFilter : newColumnFilters);
    }
  }, [columnFilters, sorting, filterTypes]);

  return {
    columnFilters,
    enableFilters,
    sorting,
    setColumnFilters,
    setEnableFilters,
    setSorting,
    setFilterTypes,
  };
};
