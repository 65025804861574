import React, { useCallback, useState } from 'react';
import { Button, Form, FormGroup, Input, Label, Modal, ModalBody } from 'reactstrap';

import * as S from './styles';

import { ITransactionsFilterModalProps } from './types';
import { useTransactions } from '@modules/Finance/contexts/TransactionsContext';
import { TransactionTypeEnum } from '@modules/Finance/ultis/transactions/enums';
export const TransactionsFilterModal = ({
  showModal,
  toggle,
  title,
}: ITransactionsFilterModalProps) => {
  // * [States] * //
  const [openAccordion, setOpenAccordion] = useState<string>('');

  // * [Hooks] * //
  const { filters } = useTransactions();

  // * [Functions] * //
  const toggleModal = useCallback(() => {
    toggle();
  }, []);

  const toggleAccordion = (id: string) => {
    if (openAccordion === id) {
      setOpenAccordion('');
      return;
    }
    setOpenAccordion(id);
  };

  const submitFilter = () => {
    filters.handleSubmit();
    toggleModal();
  };

  return (
    <Modal isOpen={showModal} toggle={toggleModal} centered>
      <S.TransactionsModalHeader toggle={toggleModal}>{title}</S.TransactionsModalHeader>

      <ModalBody>
        <S.FilterAccordion flush open={openAccordion} toggle={toggleAccordion}>
          <S.FilterAccordionItem>
            <S.FilterAccordionHeader targetId="1">Tipo</S.FilterAccordionHeader>
            <S.FilterAccordionBody accordionId="1">
              <Form>
                <FormGroup check inline>
                  <Input
                    id="expenses-radio"
                    name="type-transaction"
                    type="radio"
                    value={TransactionTypeEnum.Expense}
                    onChange={filters.handleChange}
                    checked={filters.values['type-transaction'] === TransactionTypeEnum.Expense}
                  />
                  <Label for="expenses-radio" check>
                    Despesas
                  </Label>
                </FormGroup>
                <FormGroup check inline>
                  <Input
                    id="incomes-radio"
                    name="type-transaction"
                    type="radio"
                    value={TransactionTypeEnum.Income}
                    onChange={filters.handleChange}
                    checked={filters.values['type-transaction'] === TransactionTypeEnum.Income}
                  />
                  <Label for="incomes-radio" check>
                    Receitas
                  </Label>
                </FormGroup>
                <FormGroup check inline>
                  <Input
                    id="tranfers-radio"
                    name="type-transaction"
                    type="radio"
                    value={TransactionTypeEnum.Transfer}
                    onChange={filters.handleChange}
                    checked={filters.values['type-transaction'] === TransactionTypeEnum.Transfer}
                  />
                  <Label for="tranfers-radio" check>
                    Transferências
                  </Label>
                </FormGroup>
              </Form>
            </S.FilterAccordionBody>
          </S.FilterAccordionItem>

          <S.FilterAccordionItem>
            <S.FilterAccordionHeader targetId="2">Repetição</S.FilterAccordionHeader>
            <S.FilterAccordionBody accordionId="2">
              <Form>
                <FormGroup check inline>
                  <Input
                    id="unique-checkbox"
                    type="checkbox"
                    value={filters.values['unique-checkbox'] as any}
                    onChange={filters.handleChange}
                    checked={Boolean(filters.values['unique-checkbox'])}
                  />
                  <Label for="unique-checkbox" check>
                    Únicos
                  </Label>
                </FormGroup>
                <FormGroup check inline>
                  <Input
                    id="installments-checkbox"
                    type="checkbox"
                    value={filters.values['installments-checkbox'] as any}
                    onChange={filters.handleChange}
                    checked={Boolean(filters.values['installments-checkbox'])}
                  />
                  <Label for="installments-checkbox" check>
                    Parcelados
                  </Label>
                </FormGroup>
                <FormGroup check inline>
                  <Input
                    id="fixed-checkbox"
                    type="checkbox"
                    value={filters.values['fixed-checkbox'] as unknown as string}
                    onChange={filters.handleChange}
                    checked={Boolean(filters.values['fixed-checkbox'])}
                  />
                  <Label for="fixed-checkbox" check>
                    Fixos
                  </Label>
                </FormGroup>
              </Form>
            </S.FilterAccordionBody>
          </S.FilterAccordionItem>
        </S.FilterAccordion>
      </ModalBody>

      <S.TransactionsModalFooter>
        <Button
          color="primary"
          onClick={() => submitFilter()}
          disabled={false}
          className="d-flex justify-content-center align-items-center w-100"
        >
          Filtrar
        </Button>
      </S.TransactionsModalFooter>
    </Modal>
  );
};
