import { generateToQueryString } from '@helpers/queryString';
import { financialApiInstance } from '@helpers/request/instance';
import { ERROR_STATUS_MESSAGE } from '@helpers/request/constants';

import { InstanceError } from '@apptypes/instance';

import { IExpenseData, IIncomeData } from '../types/transactions';

export const getAllExpenses = async (
  initialDate = '',
  finalDate = '',
  filterItems?: any,
  page = 1,
  limit = Number.MAX_SAFE_INTEGER,
): Promise<IExpenseData> => {
  try {
    const { data } = await financialApiInstance.get(`/api/v1/expenses`, {
      params: {
        page,
        limit,
        initialDate,
        finalDate,
        filters: filterItems,
      },
    });

    return data;
  } catch (error) {
    console.log(error);
    const errorMessage: Record<number, string> = {
      [ERROR_STATUS_MESSAGE.NOT_FOUND]: 'Despesas não encontradas',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
    };

    throw new Error(
      errorMessage[(error as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};

export const getAllIncomes = async (
  initialDate = '',
  finalDate = '',
  page = 1,
  limit = Number.MAX_SAFE_INTEGER,
  filterItems?: any,
): Promise<IIncomeData> => {
  try {
    const queryString = generateToQueryString({
      page,
      filterItems,
      otherParams: `&limit=${limit}&initialDate=${initialDate}&finalDate=${finalDate}`,
    });

    const { data } = await financialApiInstance.get(`/api/v1/income${queryString}`);

    return data;
  } catch (error) {
    const errorMessage: Record<number, string> = {
      [ERROR_STATUS_MESSAGE.NOT_FOUND]: 'Receitas não encontradas',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
    };

    throw new Error(
      errorMessage[(error as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};
