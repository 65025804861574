import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  FormGroup,
  Input,
  Label,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import styled from 'styled-components';

// * [Modal Header] * //
export const ManageTransactionsModalHeader = styled(ModalHeader)`
  border-bottom: none;
`;
export const TransactionTypeInput = styled(Input)`
  border: none;
  font-size: 16px;

  width: 100%;

  padding: 0 32px 0 0;

  cursor: pointer;

  &:focus {
    box-shadow: none;
    outline: none;
  }

  #fixed .form-switch .form-check-input {
    background-image: none !important;
  }
`;
export const FieldOption = styled.option``;

export const FieldWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
`;
export const FormFieldGroup = styled(FormGroup)<{ marginTop?: number }>`
  width: 100%;
  margin-top: ${({ marginTop }) => marginTop || 0}px;
`;
export const FieldLabel = styled(Label)`
  color: ${({ theme }) => theme.gray500};
`;
export const InputField = styled(Input)``;

export const Icon = styled.span`
  color: ${({ theme }) => theme.gray500};
  font-size: 16px;
  margin-right: 4px;
`;

export const AccordionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const TransactionAccordion = styled(Accordion)`
  .accordion-button {
    padding: 16px;

    background-color: ${({ theme }) => theme.gray100};
    color: ${({ theme }) => theme.gray500};
    border-radius: 8px !important;

    &::after {
      display: none;
    }
  }

  .accordion-body {
    padding: 16px 24px;
  }
`;
export const TransactionAccordionItem = styled(AccordionItem)``;
export const TransactionAccordionHeader = styled(AccordionHeader)``;
export const TransactionAccordionBody = styled(AccordionBody)``;

// * [Modal Footer] * //
export const ManageTransactionsModalFooter = styled(ModalFooter)`
  display: flex;
  justify-content: center;

  padding-bottom: 32px;

  border-top: none;
`;
