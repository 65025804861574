import React from 'react';
import classNames from 'classnames';
import { createColumnHelper } from '@tanstack/react-table';
import moment from 'moment';
import { moneyTransform } from '@helpers/transforms/money';

export const buildColumns = () => {
  const columnHelper = createColumnHelper();

  return [
    columnHelper.accessor('wallet.name', {
      cell: ({ getValue }: any) => <h5 className="font-size-14 mb-1">{getValue()}</h5>,
      header: 'Carteira',
      size: 15,
    }),
    columnHelper.accessor('finalAmount', {
      cell: ({ getValue }: any) => (
        <h5
          className={classNames('font-size-14 mb-1', {
            'text-danger': getValue() < 0,
            'text-success': getValue() >= 0,
          })}
        >
          {moneyTransform(getValue() || 0)}
        </h5>
      ),
      header: 'Valor final',
      size: 15,
    }),
    columnHelper.accessor('finalSwingTradeAmount', {
      cell: ({ getValue }: any) => (
        <h5
          className={classNames('font-size-14 mb-1', {
            'text-danger': getValue() < 0,
            'text-success': getValue() >= 0,
          })}
        >
          {moneyTransform(getValue() || 0)}
        </h5>
      ),
      header: 'Valor Swing Trade',
      size: 15,
    }),
    columnHelper.accessor('finalDayTradeAmount', {
      cell: ({ getValue }: any) => (
        <h5
          className={classNames('font-size-14 mb-1', {
            'text-danger': getValue() < 0,
            'text-success': getValue() >= 0,
          })}
        >
          {moneyTransform(getValue() || 0)}
        </h5>
      ),
      header: 'Valor Day Trade',
      size: 15,
    }),
    columnHelper.accessor('createdByUser.name', {
      cell: ({ getValue }: any) => <h5 className="font-size-14 mb-1">{getValue()}</h5>,
      header: 'Fechado por',
      size: 15,
    }),
    columnHelper.accessor('openedAt', {
      cell: ({ row, getValue }: any) => (
        <h5 className="font-size-14 mb-1">{moment(getValue()).format('DD/MM/YYYY HH:mm:ss')}</h5>
      ),
      header: 'Data de abertura',
      size: 10,
      meta: {
        filterType: 'date_range',
        disableFilterType: true,
      },
    }),
    columnHelper.accessor('updatedAt', {
      cell: ({ row, getValue }: any) => (
        <h5 className="font-size-14 mb-1">{moment(getValue()).format('DD/MM/YYYY HH:mm:ss')}</h5>
      ),
      header: 'Última atualização',
      size: 10,
      meta: {
        filterType: 'date_range',
        disableFilterType: true,
      },
    }),
  ];
};
