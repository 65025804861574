import React from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { Row, Col, CardBody, Card, CardTitle } from 'reactstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { IWithRouter } from '@apptypes/WithRouter';
import { Page } from '@component/common/Page';
import Spinners from '@common/Spinner';
import withRouter from '@common/withRouter';
import { REDIRECT_TIMEOUT } from '@helpers/constants';
import { getByID, update } from '@modules/Wallets/services';
import { IWalletUpdate } from '../types';
import { TYPE_ACCOUNT_TYPE, TYPE_BALANCE, TYPE_NAME } from '../constants';
import { useSelector } from 'react-redux';
import { WalletUpdateForm } from '../components/WalletForm/update';

const WalletUpdate = ({ router }: IWithRouter) => {
  const { walletId } = useParams();
  const { user }: any = useSelector((state: any) => state.Profile);

  const { data, isLoading } = useQuery({
    queryKey: ['getWalletById', walletId],
    queryFn: () => getByID(walletId || ''),
    retry: 1,
  });

  const { error, isSuccess, mutate } = useMutation({
    mutationFn: (payload: IWalletUpdate) => {
      if (walletId) {
        return update(walletId, user.id, payload);
      }

      return Promise.reject();
    },
    onSuccess: () => {
      setTimeout(() => {
        router.navigate('/wallets');
      }, REDIRECT_TIMEOUT);
    },
  });

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      active: String(data?.active) || 'true',
      value: data?.value || 0,
      name: data?.name || '',
      type: data?.type || '',
    },
    validationSchema: Yup.object({
      value: Yup.number().required(TYPE_BALANCE),
      name: Yup.string().required(TYPE_NAME),
      type: Yup.string().required(TYPE_ACCOUNT_TYPE),
    }),
    onSubmit: async (payload: IWalletUpdate) => mutate(payload),
  });

  return (
    <Page
      title="Editar carteiras"
      breadcrumb={{
        title: 'Listar carteiras',
        titleHref: '/wallets',
        breadcrumbItem: 'Editar carteira',
      }}
    >
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <CardTitle className="mb-4">Editar carteira</CardTitle>
              <div className="p-2 mt-3">
                {isLoading ? (
                  <Spinners />
                ) : (
                  <WalletUpdateForm
                    loading={isLoading}
                    validation={validation}
                    registrationError={error?.message}
                    showAlert={isSuccess}
                    type="edit"
                  />
                )}
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Page>
  );
};

export default withRouter(WalletUpdate);
